/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { pageContent, pageTitle } from '../CommonStyles';
import { IntegrationTiles } from './IntegrationTiles';

export function Overview() {
    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Integration overview</h1>
            <p>
                You can integrate smart shape widget with your existing design studio to add support for advanced shape library to enable
                customer to add variety of different shapes generated dynamically as needed
            </p>
            <IntegrationTiles />
        </div>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment, Children, ReactElement } from 'react';
import { jsx, css } from '@emotion/react';
import { appTheme } from '../../theme';
import { SideNavSection, SideNavSectionProps } from './SideNavSection';
import { SideNavEntry, SideNavEntryProps } from './SideNavEntry';

const sidePanelStyle = css`
    font-family: 'Gotham-Rounded-Book';
    box-sizing: border-box;
    padding: 0 20px 15px;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    order: 1;
    background-color: ${appTheme.colors.secondaryBackground};
`;

const sidePanelMobileStyle = css`
    position: fixed;
    height: 100%;
    top: 60px;
    transition: 0.5s;
    min-width: 260px;
    padding: 5px 20px;
`;

const sidePanelHeaderStyle = css`
    text-align: right;
    font-size: 30px;
`;

const closeSidePanelButtonStyle = css`
    font-family: 'Gotham-Rounded-Book';
    cursor: pointer;
    border: none;
    font-size: 30px;
    background: inherit;
`;

interface SidePanelProps {
    children:
        | ReactElement<SideNavEntryProps>[]
        | ReactElement<SideNavEntryProps>
        | ReactElement<SideNavSectionProps>[]
        | ReactElement<SideNavSectionProps>;
    isCloseable: boolean;
    onClose: () => void;
    position: 'absolute' | 'relative';
}

function isSideNavSection(element: ReactElement<any>): element is ReactElement<SideNavSectionProps> {
    return element.type === SideNavSection;
}

export function SidePanel({ children, isCloseable, onClose, position }: SidePanelProps) {
    return (
        <ul css={[sidePanelStyle, position === 'absolute' ? sidePanelMobileStyle : '']}>
            {isCloseable && (
                <div css={sidePanelHeaderStyle}>
                    <button
                        type='button'
                        css={closeSidePanelButtonStyle}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        &times;
                    </button>
                </div>
            )}
            {Children.map(children, (child) => {
                if (isSideNavSection(child)) {
                    return (
                        <Fragment>
                            <SideNavSection
                                label={child.props.label}
                                path={child.props.path}
                                onClick={child.props.onClick ? child.props.onClick : onClose}
                                page={child.props.page}
                            >
                                {Children.map(child.props.children, (entry) => {
                                    if (entry) {
                                        const { label, path, url } = entry.props;
                                        const fullPath = `/${child.props.path}/${path ?? ''}`;
                                        return (
                                            <SideNavEntry
                                                key={fullPath}
                                                path={path}
                                                fullPath={fullPath}
                                                label={label}
                                                url={url}
                                                onClick={onClose}
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </SideNavSection>
                        </Fragment>
                    );
                }

                return null;
            })}
        </ul>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState, useContext } from 'react';

import {
    getCurrentShapeInRenderingFormat,
    getCurrentSvg,
    Layout,
    SmartShapes,
    SupportedLanguages,
} from '@cimpress-technology/smart-shapes';
import { CodeBlock } from '../../components/codeBlock/CodeBlock';
import { pageContent, pageTitle, primaryButtonStyle } from '../CommonStyles';
import { AuthContext } from '../../auth/AuthContext';

const widgetContainerStyle = css`
    padding: 10px;
    max-width: 500px;
    border: dashed 1px lightgray;
    border-radius: 15px;
    height: 500px;
`;

const Demo = () => {
    const [result, setResult] = useState<string>();
    const [locale, setLocale] = useState();

    const { accessToken } = useContext(AuthContext);

    const handleGetSvg = () => {
        getCurrentSvg().then((svgString: string) => setResult(svgString));
    };

    const handleGetRenderingFormat = () => {
        getCurrentShapeInRenderingFormat(50, 50).then((data: any) => {
            Array.isArray(data) && data.length > 0 && setResult(JSON.stringify(data[0], undefined, 2));
            console.log(data[0]);
        });
    };

    const changeLanguage = (e: any) => {
        setLocale(e.target.value);
    };

    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Demo</h1>
            <div css={widgetContainerStyle}>
                <SmartShapes
                    locale={locale}
                    tenantId='SceneTestGroup'
                    hostApp='SmartShapesExample'
                    layout={Layout.PreviewOnSide}
                    authConfig={{ authType: 'Bearer', value: accessToken }}
                />
            </div>
            <div style={{ marginTop: '15px' }}>
                <select onChange={changeLanguage}>
                    {SupportedLanguages &&
                        SupportedLanguages.map((lang) => (
                            <option key={lang.code} value={lang.code}>
                                {lang.text}
                            </option>
                        ))}
                </select>
                &nbsp;
                <button css={primaryButtonStyle} onClick={handleGetSvg}>
                    Get SVG
                </button>
                &nbsp;
                <button css={primaryButtonStyle} onClick={handleGetRenderingFormat}>
                    Get rendering format
                </button>
            </div>
            <div style={{ marginTop: '10px' }}>{result && <CodeBlock>{result}</CodeBlock>}</div>
        </div>
    );
};

export default Demo;

/** @jsxRuntime classic */
/** @jsx jsx */
import { memo, useState, useContext, useEffect, useMemo } from 'react';
import { jsx } from '@emotion/react';
import { Button, Label, Tag, Select } from '@cimpress/react-components';

import { MAX_FILE_SIZE_LIMIT } from '../../constants';
import { AdminContext } from '../../context/AdminContext';
import { UploadItem } from '../../admin.types';
import { AuthContext } from '../../context/AuthContext';
import { createAsset } from '../../services/asset.service';
import { getTrimmedTags } from '../../services/common.service';
import { formatBytes } from '../../utility/image.utility';
import { handleEnterKeyPress } from 'admin/utility/keyEvent.utility';

interface UploadProps {
    item: UploadItem;
}

function Upload({ item }: UploadProps) {
    const { setUploadList, uploadList, collectionList, renderingView, setRenderingView } = useContext(AdminContext);
    const { accessToken } = useContext(AuthContext);

    const [isEditingTag, setIsEditingTag] = useState<boolean>(false);
    const [isCreateAssetError, setIsCreateAssetError] = useState<boolean>(false);
    const [tagName, setTagName] = useState<string>('');
    const [tagList, setTagList] = useState<string[]>(item.tags);
    const [isApprovedLoader, setIsApprovedLoader] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        setTagList((state) => Array.from(new Set([...state, ...item.tags])));
    }, [item.tags]);

    useEffect(() => {
        let message = '';
        if (!item.isValidImageSize) {
            message = `File Size should be less than ${formatBytes(MAX_FILE_SIZE_LIMIT)}`;
        } else if (!item.isValidImageFormat) {
            message = 'unsupported File format';
        } else if (item.isValidImageSize && item.isValidImageFormat && !item.renderFormatUrl) {
            message = 'File cannot be processed';
        }
        setErrorMessage(message);
    }, [item.isValidImageSize, item.isValidImageFormat, item.renderFormatUrl]);

    const deleteTag = (index: number) => {
        const list = [...tagList];
        list.splice(index, 1);
        setTagList(list);
    };
    const deleteUploadItem = () => {
        const list = uploadList.filter((uploadItem) => uploadItem.id !== item.id);
        if (renderingView.fileId === item.id) {
            setRenderingView({ fileId: list.length ? list[0].id : '', fileUrl: list.length ? list[0].renderFormatUrl : '' });
        }
        setUploadList(list);
    };
    const imageClick = () => {
        setRenderingView({ fileId: item.id, fileUrl: item.renderFormatUrl });
    };
    const handleApprove = async () => {
        setIsApprovedLoader(true);
        let isCollectionExistsInTags = false; // to handle for duplication of collection value in tags
        const tagsList = tagList.map((tag) => {
            const lowercaseTag = tag.toLowerCase();
            if (lowercaseTag === item.collection.value) isCollectionExistsInTags = true;
            return lowercaseTag;
        });
        // Adding collection as tag to allow search/filter by collection text
        if (!isCollectionExistsInTags) {
            tagsList.push(item.collection.value);
        }
        const { error, id } = await createAsset(
            item.image,
            { authType: 'Bearer', value: accessToken },
            { collection: item.collection, tagList: tagsList }
        );
        const list = uploadList.map((uploadItem) => {
            if (uploadItem.id === item.id) {
                uploadItem.isApproved = !error;
                uploadItem.id = id || item.id;
            }
            return uploadItem;
        });
        if (!error) {
            setTagList(tagsList);
            setIsEditingTag(false);
        }
        setUploadList(list);
        setErrorMessage(error);
        setIsApprovedLoader(false);
        setIsCreateAssetError(!!error);
    };
    const onSaveTag = () => {
        const tagSet = getTrimmedTags(tagName);
        if (tagSet && tagSet.length) {
            const uniqueTagSet = Array.from(new Set([...tagList, ...tagSet]));
            setTagList(uniqueTagSet);
            setTagName('');
        }
    };
    const onCancelTag = () => {
        setTagName('');
        setIsEditingTag(false);
    };

    const buttonCaption = useMemo(() => {
        let message = 'Approve';
        if (!isApprovedLoader && isCreateAssetError) {
            message = 'Try Again';
        } else if (isApprovedLoader) {
            message = 'Approving...';
        } else if (item.isApproved) {
            message = 'Approved';
        }
        return message;
    }, [isApprovedLoader, isCreateAssetError, item.isApproved]);
    const setItemCollection = (e: any) => {
        const newUploadList = uploadList.map((element) => {
            if (element.id === item.id) {
                element.collection = e;
            }
            return element;
        });
        setUploadList(newUploadList);
    };
    const isError = !item.isValidImageFormat || !item.isValidImageSize || !item.renderFormatUrl;
    return (
        <tr className={renderingView.fileId === item.id ? 'uploads-selected' : ''}>
            <td>
                <div className='upload-image-div'>
                    <img className='upload-image' onClick={imageClick} src={URL.createObjectURL(item.image)} alt={item.name} />
                </div>
            </td>
            <td>
                <div>
                    <Label text={formatBytes(item.size)} className={errorMessage ? 'upload-size-is-error' : 'upload-size-is-not-error'} />

                    {errorMessage && <div className='upload-unsupported-file'>{errorMessage}</div>}
                </div>
            </td>
            <td className='upload-tags-column'>
                {!isError &&
                    (item.isFetchingTags ? (
                        <div className='upload-fetching-tags'>Fetching Tags</div>
                    ) : (
                        <div>
                            <div className='upload-tags-div'>
                                {tagList.map((tag: string, index: number) => {
                                    return !item.isApproved ? (
                                        <Tag key={tag} value={tag} removable onRemoveClick={() => deleteTag(index)} />
                                    ) : (
                                        <Tag key={tag} value={tag} />
                                    );
                                })}
                            </div>
                            {isEditingTag ? (
                                <div className='upload-tags-edit-root'>
                                    <div>
                                        <input
                                            className='upload-tags-edit-input'
                                            value={tagName}
                                            onChange={(e) => setTagName(e.target.value)}
                                            onKeyPress={handleEnterKeyPress(onSaveTag)}
                                        />
                                    </div>
                                    <div className='upload-tag-btn-div'>
                                        <Button
                                            variant='primary'
                                            className='upload-tags-btn add-tag-btn'
                                            onClick={onSaveTag}
                                            disabled={tagName.length === 0 || !tagName.trim()}
                                        >
                                            Add
                                        </Button>
                                        <Button variant='primary' className='upload-tags-btn cancel-btn' onClick={onCancelTag}>
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                !item.isApproved && (
                                    <div className='upload-tags-btn add-tags' onClick={() => setIsEditingTag(true)}>
                                        Add Tags
                                    </div>
                                )
                            )}
                        </div>
                    ))}
            </td>
            <td className='upload-collection-actions-column'>
                {!isError && (
                    <Select
                        isDisabled={item.isApproved}
                        label='Select a Collection'
                        value={item.collection}
                        options={collectionList}
                        onChange={setItemCollection}
                    />
                )}
            </td>
            <td className='upload-collection-actions-column'>
                <div className='upload-approve-div'>
                    {!isError && (
                        <Button
                            disabled={item.isApproved || isApprovedLoader || item.isFetchingTags}
                            variant='primary'
                            size='sm'
                            onClick={handleApprove}
                        >
                            {buttonCaption}
                        </Button>
                    )}

                    <Button disabled={item.isApproved || item.isFetchingTags} variant='primary' size='sm' onClick={deleteUploadItem}>
                        Delete
                    </Button>
                </div>
            </td>
        </tr>
    );
}

export default memo(Upload);

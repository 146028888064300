/* eslint-disable no-multi-str */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { PropsDocumentation } from '../../components/PropsDocumentation';
import { pageContent, pageTitle, subSectionHeader } from '../CommonStyles';
import { ThemeSetup } from './ThemeSetup';
import { UsageInstructions } from './UsageInstructions';

export function Documentation() {
    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Smart Shape package</h1>
            <h3>Integration Setup</h3>
            <p>Before getting started make sure you know how you will authenticate your users against Cimpress Technology APIs.</p>
            <p>
                We <b>strongly recommend</b> using TypeScript to help make the integration easier. Our packages are published with typings
                available (no additional dependency required).
            </p>
            <h4 css={subSectionHeader} id='GettingStartedSmartShape'>
                Getting started
            </h4>
            <ul>
                <li>
                    Ensure your NPM user has access to CT Artifactory, if not goto{' '}
                    <Link to='/documentation/integration-setup'>package integration setup</Link>
                </li>
                <li>npm install @cimpress-technology/smart-shapes</li>
                <li>Use the module found below</li>
            </ul>

            <h4 css={subSectionHeader} id='step1-documentation'>
                Step 1: Smart Shapes React Component
            </h4>
            <UsageInstructions />
            <PropsDocumentation
                props={[
                    {
                        name: 'locale',
                        required: true,
                        description: 'Language culture',
                        content: 'Language culture',
                    },
                    {
                        name: 'layout',
                        required: false,
                        description: 'Layout for widget. default is "PreviewOnSide".',
                        // eslint-disable-next-line
                        content:
                            'This prop allow to to specify layout for widget to have custom look on how different sections will be placed.\
                Available options are: PreviewOnTop, PreviewOnSide, PreviewOnBottom',
                    },
                    {
                        name: 'hostApp',
                        required: false,
                        description: 'Consumer app name',
                        content: 'Consumer app name',
                    },
                    {
                        name: 'tenantId',
                        required: false,
                        description: 'Tenant identifier',
                        content: 'Tenant identifier',
                    },
                    {
                        name: 'authConfig',
                        required: true,
                        description: 'Authentication object',
                        content: `This object of type {"authType":"Bearer" | "session","value":"Authorization token"}
                             is used to authorize the package`,
                    },
                    {
                        name: 'dragAndDrop',
                        required: false,
                        description: 'Enables user to drag And drop shapes. By default this feature is not provided.',
                        // eslint-disable-next-line
                        content:
                            'This prop enables you to make your shapes draggable and droppable. It takes three properties.\n \
                        1) dropTargetClassName(string): className of your drop area.\n\
                        2) canvasToShapePercentage(number): this ratio indicates shape size depending on Canvas size.\n \
                        3) onDrop (( position: { x: number; y: number }) => void): This will be called when you\n \
                          drop your shape on droppable area.\n \
                          It will give position of the dropped shape.',
                    },
                ]}
            />

            <h4 css={subSectionHeader} id='step2-documentation'>
                Step 2: Provide custom theme to Smart Shapes widget
            </h4>
            <ThemeSetup />

            <h4 css={subSectionHeader}>Do you want to integrate this widget with designer studio?</h4>
            <p>
                <Link to='/integration'>click here</Link> to know more
            </p>
        </div>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './auth';
import { ResponsiveHeader } from './components/responsiveHeader/ResponsiveHeader';

import './App.css';

import Designer from './designer/Designer';
import { Home } from './screens/home/Home';
import { SupportIndex } from './screens/support/SupportIndex';
import { DemoIndex } from './screens/demo/DemoIndex';
import { IntegrationIndex } from './screens/integration/IntegrationIndex';
import { DocumentationIndex } from './screens/documentation/DocumentationIndex';
import { appTheme } from './theme';
import packageJson from '../package.json';
import Admin from 'admin/components/App';

const PublicUrl = process.env.PUBLIC_URL || '';

const appStyle = css`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
`;

const screenContainerStyle = css`
    background-color: ${appTheme.colors.secondaryBackground};
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    order: 2;
    overflow: auto;
`;

export const menuItems = [
    {
        path: '/demo',
        label: 'Demo',
    },
    {
        path: '/documentation',
        label: 'Documentation',
    },
    {
        path: '/integration',
        label: 'Integration',
    },
    {
        path: '/support',
        label: 'Support',
    },
];

export function App() {
    return (
        <AuthProvider>
            <Router basename={PublicUrl}>
                <div css={appStyle}>
                    <div css={screenContainerStyle}>
                        <Switch>
                            <Route path='/demo'>
                                <DemoIndex />
                            </Route>
                            <Route path='/documentation'>
                                <DocumentationIndex />
                            </Route>
                            <Route path='/integration/designer/demo'>
                                <Designer currentLocale='en-US' />
                            </Route>
                            <Route path='/integration'>
                                <IntegrationIndex />
                            </Route>
                            <Route path='/support'>
                                <SupportIndex />
                            </Route>
                            <Route path='/admin'>
                                <Admin />
                            </Route>
                            <Route path='/'>
                                <Home />
                            </Route>
                        </Switch>
                    </div>
                    <ResponsiveHeader
                        logoUrl={`${process.env.PUBLIC_URL}/img/logo.svg`}
                        title='Smart shapes'
                        version={packageJson ? packageJson.version : 'unspecified'}
                        navMenuItems={menuItems}
                    />
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;

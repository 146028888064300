/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useContext, useEffect, Fragment } from 'react';
import { jsx } from '@emotion/react';
import { Button, Select, Alert, Dropdown, Modal } from '@cimpress/react-components';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg';

import Tabs from './Tabs';
import { Collection } from '../admin.types';
import { FileUpload } from './uploads/FileUpload';
import { AdminContext } from '../context/AdminContext';
import { getTrimmedTags, toTitleCase } from '../services/common.service';
import { AuthContext } from '../context/AuthContext';
import { getCollectionList, updateCollectionAsset } from '../services/asset.service';
import { handleEnterKeyPress } from 'admin/utility/keyEvent.utility';

const Admin = () => {
    const { collectionList, setCollectionList } = useContext(AdminContext);
    const { accessToken } = useContext(AuthContext);

    const [collection, setCollection] = useState<Collection>(collectionList[0]);
    const [showAddCollection, setShowAddCollection] = useState<boolean>(false);
    const [collectionName, setCollectionName] = useState<string>('');
    const [isAddingCollection, setIsAddingCollection] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [item, setItem] = useState<Collection | null>(null);
    const onCancel = () => {
        setCollectionName('');
        setShowAddCollection(false);
        setErrorMessage('');
    };
    const onSave = async () => {
        setIsAddingCollection(true);
        const collectionSet = getTrimmedTags(collectionName);
        if (collectionSet && collectionSet.length) {
            const index = collectionList.findIndex((item) => item.value === collectionSet[0].toLowerCase());
            if (index === -1) {
                const list = [...collectionList];
                list.push({ label: toTitleCase(collectionSet[0]), value: collectionSet[0].toLowerCase() });

                const assetMessage = await updateCollectionAsset({ authType: 'Bearer', value: accessToken }, list);
                if (!assetMessage) {
                    setCollectionList(list);
                }
                setErrorMessage(assetMessage);
            }
            setCollectionName('');
        }
        setIsAddingCollection(false);
    };

    const getCollectionLists = async () => {
        const { collections, error } = await getCollectionList({ authType: 'Bearer', value: accessToken });
        if (!error) {
            setCollectionList(collections);
            setCollection(collections[0]);
        }
        setErrorMessage(error);
    };

    useEffect(() => {
        if (accessToken) getCollectionLists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    const deleteCollectionHandler = async (collection: Collection): Promise<void> => {
        const newCollectionList = collectionList.filter((item) => item.value !== collection.value);
        const assetMessage = await updateCollectionAsset({ authType: 'Bearer', value: accessToken }, newCollectionList);
        if (!assetMessage) {
            setCollectionList(newCollectionList);
        } else {
            setErrorMessage(assetMessage);
        }
        setCollection(newCollectionList[0]);
        setItem(null);
    };

    return (
        <div className='admin-container'>
            {errorMessage && <Alert title='Title: Error' message={errorMessage} />}
            <div className='collection-container'>
                <div className='collection-container-box'>
                    <div className='application-name'>Shapes Library</div>
                    <div className='collection-container-select-root'>
                        <div className='collection-container-select-root-flex'>
                            <div className='collection-container-select-root-div'>
                                <p className='collection-container-select-name'>Select Collection :</p>
                            </div>
                            <div className='collection-container-select-div'>
                                <Select
                                    value={collection}
                                    label='Select a Collection'
                                    options={collectionList}
                                    onChange={(e) => e && setCollection(e)}
                                />
                            </div>
                        </div>
                        <div className='collection-container-btn-root'>
                            <div className='collection-container-btn-div'>
                                <div>
                                    {showAddCollection ? (
                                        <div className='admin-collection-div'>
                                            <div className='admin-collection-input-div'>
                                                <input
                                                    value={collectionName}
                                                    onChange={(e) => setCollectionName(e.target.value)}
                                                    onKeyPress={handleEnterKeyPress(onSave)}
                                                />
                                            </div>
                                            <div className='admin-collection-save-cancel-div'>
                                                <Button variant='primary' disabled={isAddingCollection} size='sm' onClick={onSave}>
                                                    Save
                                                </Button>
                                                <Button variant='primary' size='sm' onClick={onCancel}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <Button variant='primary' size='lg' onClick={() => setShowAddCollection(true)}>
                                            Add a new Collection
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    <div className='collection-container-btn-dropdown'>
                                        <Dropdown title='Delete a collection'>
                                            {collectionList.map((item) => (
                                                <Fragment key={item.label}>
                                                    <div className='dropdown-option'>
                                                        <span>{item.label}</span>
                                                        <Button variant='anchor' icon={<DeleteIcon />} onClick={() => setItem(item)} />
                                                    </div>
                                                    <div className='horizontalLine' />
                                                </Fragment>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {item && (
                                        <Modal
                                            className='foobar'
                                            status='danger'
                                            show={!!item}
                                            onRequestHide={() => setItem(null)}
                                            title='Delete Collection?'
                                            footer={
                                                <div>
                                                    <button className='btn btn-default' onClick={() => setItem(null)}>
                                                        Cancel
                                                    </button>
                                                    <button className='btn btn-default' onClick={() => deleteCollectionHandler(item)}>
                                                        Delete
                                                    </button>
                                                </div>
                                            }
                                        >
                                            Are you sure you want to delete this collection: {item?.label}?
                                        </Modal>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='upload-container'>
                <FileUpload collection={collection} />
            </div>
            <div className='tabs-container'>
                <Tabs />
            </div>
        </div>
    );
};
export default Admin;

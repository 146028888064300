/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { appTheme } from 'theme';
import { baseTileStyle } from '../CommonStyles';

const integrationList = [
    {
        title: 'Cimpress Designer studio',
        description: 'Integration with cimpress designer studio',
        navPath: 'designer',
        icon: 'designerStudio.svg',
    },
    {
        title: 'Design experience built using Design-Stack',
        description: 'Integration with design experience developed using design stack',
        navPath: 'design-experience',
        icon: 'designStack.svg',
    },
];
const experienceTilesStyle = css`
    display: flex;
    flex-wrap: wrap;
`;

const experienceTile = css`
    ${baseTileStyle}
    height: 278px;
    width: 248px;
    background: ${appTheme.colors.secondaryBackground};
    border-radius: 6px;

    :hover {
        cursor: pointer;
        border: 2px solid ${appTheme.colors.primary};
    }

    .icon-container {
        display: flex;
        height: 180px;
        box-sizing: border-box;
        padding: 30px 30px 0 30px;
    }

    .component-title {
        font-size: 19px;
        color: ${appTheme.colors.primary};
    }
`;
const disabledExperienceTile = css`
    ${baseTileStyle}
    height: 278px;
    width: 248px;
    background: ${appTheme.colors.secondaryBackground};
    border-radius: 6px;

    :hover {
        cursor: not-allowed;
        border: 2px solid ${appTheme.colors.primary};
    }

    .icon-container {
        display: flex;
        height: 180px;
        box-sizing: border-box;
        padding: 30px 30px 0 30px;
    }

    .component-title {
        font-size: 19px;
        color: ${appTheme.colors.primary};
    }
`;
const blocker = css`
    background: ${appTheme.colors.blockerBackground};
    position: absolute;
    top: 50%;
    height: 10%;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message {
        font: normal normal bold 20px/25px Montserrat;
        letter-spacing: 0px;
        color: ${appTheme.colors.primaryText};
    }
`;

export function IntegrationTiles() {
    const history = useHistory();

    const cardClickHandler = (navPath?: string) => {
        if (navPath) {
            history.push(`/integration/${navPath}`);
        }
    };

    return (
        <div css={experienceTilesStyle}>
            {integrationList.map(({ navPath, ...info }, index) => (
                <div
                    role='button'
                    key={index}
                    css={navPath === 'design-experience' ? disabledExperienceTile : experienceTile}
                    onClick={() => (navPath === 'design-experience' ? null : cardClickHandler(navPath))}
                    title={info.description}
                >
                    <p className='component-title'>{info.title}</p>
                    <img src={`${process.env.PUBLIC_URL}/img/${info.icon}`} alt='Design Stack' height='auto' width='150' />
                    {navPath === 'design-experience' && (
                        <div css={blocker}>
                            <p className='message'> COMING SOON </p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

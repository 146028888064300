/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { appTheme } from '../../theme';

const logoStyle = css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    box-sizing: border-box;
    user-select: none;

    a,
    a:visited {
        color: ${appTheme.colors.primaryText};
        text-decoration: none;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }
`;

const logoHeadingStyle = css`
    font-family: 'Gotham-Rounded-Book';
    font-weight: bold;
    padding: 0px 10px;
`;

export interface LogoProps {
    logoUrl: string;
    title: string;
    version: string;
    onClick?: () => void;
}

export function Logo({ logoUrl, title, version, onClick }: LogoProps) {
    return (
        <div css={logoStyle}>
            <Link to='/' onClick={onClick}>
                <img src={logoUrl} alt='logo' width={24} height={24} />
                <span css={logoHeadingStyle}>
                    {title}
                    <span css={{ fontWeight: 'normal' }}>&nbsp;(v {version})</span>
                </span>
            </Link>
        </div>
    );
}

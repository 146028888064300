import { useContext } from 'react';
import { SmartShapes, Layout } from '@cimpress-technology/smart-shapes';
import { View, PropTypes } from 'react-view';

import { AuthContext } from '../../auth/AuthContext';

enum Locale {
    'en-US' = 'en-US',
    'nl-NL' = 'nl-NL',
    'de-DE' = 'de-DE',
    'it-IT' = 'it-IT',
}

export function InteractiveDemo() {
    const { accessToken } = useContext(AuthContext);
    const auth = { authType: 'Bearer', value: accessToken };
    return (
        <div>
            <p>You can update component props to evaluate and see different features and options supported</p>
            <View
                componentName='SmartShapes'
                props={{
                    locale: {
                        value: 'en-US',
                        type: PropTypes.Enum,
                        options: Locale,
                        description: 'Locale value',
                    },
                    layout: {
                        value: 'PreviewOnSide',
                        type: PropTypes.Enum,
                        options: Layout,
                        description: 'Layout option',
                    },
                    hostApp: {
                        value: 'SmartShapeDemo',
                        type: PropTypes.String,
                        description: 'Consumer app name',
                    },
                    tenantId: {
                        value: 'SceneTestGroup',
                        type: PropTypes.String,
                        description: 'Tenant identifier',
                    },
                    authConfig: {
                        value: JSON.stringify(auth, null, 2),
                        type: PropTypes.Object,
                        description: 'Authentication Config Object',
                    },
                }}
                scope={{
                    SmartShapes,
                }}
                imports={{
                    '@cimpress-technology/smart-shapes': {
                        named: ['SmartShapes'],
                    },
                }}
            />
        </div>
    );
}

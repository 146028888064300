/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { CodeBlock } from '../../components/codeBlock/CodeBlock';

export function SvgToCimdocInstruction() {
    const renderData = `import { convertSvgToCimdoc } from "@cimpress-technology/svg-to-cimdoc;

const svgString = "<svg viewBox='0 20 200 160'>
  <path d=' M0,50 L100,50 L100,20 L200,100 L100,180 L100,150 L0,150 Z' stroke='%231a171b' stroke-width='1' stroke-linecap='butt'
  stroke-linejoin='arcs' stroke-dasharray='[2]' fill='%23aaaaaa' transform='rotate(30)' />
  </svg>";
const widthInMM = 100;
const shouldMergePath = false;

convertSvgToCimdoc(svgString, widthInMM, shouldMergePath).then((data: RenderingFormat[]) => data));
// output:-
// [
//  {
//      "type":"curve",
//      "position":{"x":"0mm","y":"0mm","height":"40.673733777952094mm","width":"50.84216722244012mm"},
//      "curves":[
//                {"operation":"moveTo","position":{"x":"0mm","y":"50mm"}},
//                {"operation":"lineTo","position":{"x":"100mm","y":"50mm"}},
//                {"operation":"lineTo","position":{"x":"100mm","y":"20mm"}},
//                {"operation":"lineTo","position":{"x":"200mm","y":"100mm"}},
//                {"operation":"lineTo","position":{"x":"100mm","y":"180mm"}},
//                {"operation":"lineTo","position":{"x":"100mm","y":"150mm"}},
//                {"operation":"lineTo","position":{"x":"0mm","y":"150mm"}},
//                {"operation":"close","position":{"x":"0mm","y":"50mm"}}
//              ],
//      "viewBox":{"x":"0mm","y":"20mm","width":"200mm","height":"160mm"},
//      "stroke":{
//        "color":"rgb(#1a171b)",
//        "thickness":"1pt",
//        "lineCap":"butt",
//        "lineJoin":"arcs",
//        "dashPattern":{"segments":[{"length":"[2]"}]}
//      },
//      "color":"rgb(#aaaaaa)"}]`;

    const cimdocArrString = `
interface RenderingFormat = {
  type: string;
  position: {
      x: string;
      y: string;
      height: string;
      width: string;
  };
  curves: Curve[];
  viewBox: {
      x: string;
      y: string;
      width: string;
      height: string;
  };
  stroke: {
      color: string;
      thickness: string;
      lineCap?: string;
      lineJoin?: string;
      dashPattern?: {
          segments: { length: string }[];
      };
  };
  color: string;
}`;

    const cimdocArrPathDataString = `
interface RenderingFormat = {
  type: string;
  position: {
      x: string;
      y: string;
      height: string;
      width: string;
  };
  svgPathData: string;
  svgPathDataUnit: string;
  viewBox: {
      x: string;
      y: string;
      width: string;
      height: string;
  };
  stroke: {
      color: string;
      thickness: string;
      lineCap?: string;
      lineJoin?: string;
      dashPattern?: {
          segments: { length: string }[];
      };
  };
  color: string;
}`;

    const parameterDescription = `
interface ConvertSvgToCimdocProps {
    svgString: string;
    widthInMM?: number;
    heightInMM?: number;
    shouldMergePath?: boolean;
    format?: CimdocShapeFormat;
}

export const enum CimdocShapeFormat {
    SvgPath = 'svgpath',
    PathOperations = 'pathOperation',
}
`;

    return (
        <div>
            <p>
                After installing the SvgToCimdoc package, you're able to import the `@cimpress-technology/svg-to-cimdoc` package into your
                application. In order to use the new functions, you'll need to import it into your React application and use it. See sample
                code below
            </p>
            <div>
                <h4 id='convertSvgToCimdoc'>convertSvgToCimdoc</h4>
                <pre className='code-section'>
                    async convertSvgToCimdoc(props: ConvertSvgToCimdocProps): Promise&lt;RenderingFormat[]&gt;
                </pre>
                <p>
                    Parameters: <pre className='code-section'>{parameterDescription}</pre>
                </p>
                <p>
                    Returns (when format is SvgPath): <pre className='code-section'>{cimdocArrPathDataString}</pre>
                </p>
                <p>
                    Returns (when format is PathOperations): <pre className='code-section'>{cimdocArrString}</pre>
                </p>
                <p>
                    Example usage: <CodeBlock>{renderData}</CodeBlock>
                </p>

                <h4 id='convertSvgToCimdocSync'>convertSvgToCimdocSync</h4>
                <pre className='code-section'>convertSvgToCimdocSync(props: ConvertSvgToCimdocProps): RenderingFormat[]</pre>
                <p>
                    Parameters: <pre className='code-section'>{parameterDescription}</pre>
                </p>
                <p>
                    Returns: <pre className='code-section'>{cimdocArrString}</pre>
                </p>
            </div>
        </div>
    );
}

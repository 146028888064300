import { useContext } from 'react';

import { FileUploadButton } from '../fileUploadButton/FileUploadButton';

import './fileUpload.css';
import { FileDropZone } from '../fileDropZone/FileDropZone';
import { ValidImageList, UploadItem, Collection } from '../../admin.types';
import { isValidImageSize, isValidImageFormat } from '../../utility/image.utility';
import { AuthContext } from '../../context/AuthContext';
import { AdminContext } from '../../context/AdminContext';
import { ACCEPTED_FILE_FORMATS, UPLOADS_TAB } from '../../constants';
import { getAllRenderingFormatViewUrl, getAllTagList } from '../../services/upload.service';

interface FileUploadProps {
    collection: Collection;
}

export function FileUpload({ collection }: FileUploadProps) {
    const { setUploadList, renderingView, uploadList, setIsUploading, isUploading, setRenderingView, setSelectedTab, selectedTab } =
        useContext(AdminContext);
    const { accessToken } = useContext(AuthContext);

    const onFileUpload = async (files: File[]) => {
        setIsUploading(true);
        if (selectedTab !== UPLOADS_TAB) {
            const renderingObject = renderingView;
            renderingObject.fileId = uploadList.length ? uploadList[0].id : '';
            renderingObject.fileUrl = uploadList.length ? uploadList[0].renderFormatUrl : '';
            setRenderingView(renderingObject);
            setSelectedTab(UPLOADS_TAB);
        }
        const filesList = Object.values(files);
        const validUploadList: ValidImageList[] = [];
        let newUploadList: UploadItem[] = filesList.map((item: any) => {
            const obj = {
                id: `${item.name}${Math.random().toFixed(3)}`,
                size: item.size,
                tags: [],
                collection,
                isApproved: false,
                image: item,
                isValidImageSize: isValidImageSize(item.size),
                isValidImageFormat: isValidImageFormat(item.type),
                name: item.name,
                renderFormatUrl: '',
                isFetchingTags: false,
            };
            if (obj.isValidImageFormat && obj.isValidImageSize) {
                validUploadList.push({ id: obj.id, image: obj.image });
            }

            return obj;
        });

        if (validUploadList.length) {
            const urlObj = await getAllRenderingFormatViewUrl(validUploadList, { authType: 'Bearer', value: accessToken });
            newUploadList = newUploadList.map((item) => ({
                ...item,
                renderFormatUrl: urlObj[item.id] || '',
                isFetchingTags: !!urlObj[item.id],
            }));
        }
        if (!renderingView.fileId) {
            setRenderingView({
                fileId: newUploadList.length ? newUploadList[0].id : '',
                fileUrl: newUploadList.length ? newUploadList[0].renderFormatUrl : '',
            });
        }
        setIsUploading(false);
        setUploadList([...newUploadList, ...uploadList]);

        const tagsObj = await getAllTagList(newUploadList, { authType: 'Bearer', value: accessToken });
        const list = newUploadList.map((item) => ({ ...item, isFetchingTags: false, tags: tagsObj[item.id] || [] }));
        setUploadList([...list, ...uploadList]);
    };

    return (
        <div className='file-upload show-upload-button'>
            <FileDropZone onFileUpload={onFileUpload}>
                <>
                    <FileUploadButton onFileUpload={onFileUpload} type='primary' accept={ACCEPTED_FILE_FORMATS}>
                        {isUploading ? 'Uploading...' : 'Upload SVG'}
                    </FileUploadButton>
                </>
            </FileDropZone>
        </div>
    );
}

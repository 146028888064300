import { CodeBlock } from '../../components/codeBlock/CodeBlock';

export function SvgPathUsageInstruction() {
    const GetPathDataExample = `import { GetPathData, ShapeTypes } from "@cimpress-technology/svg-path";

const arrow: ArrowShape = {
  type: ShapeTypes.Arrow,
  headWidth: 20,
  tailWidth: 20,
};

GetPathData(arrow);    // return string`;

    const GetPathExample = `import { GetPath, Shape, ShapeTypes, PathProperties } from "@cimpress-technology/svg-path";

const arrow: ArrowShape = {
  type: ShapeTypes.Arrow,
  headWidth: 20,
  tailWidth: 20,
};

const strokeProps: Stroke = {
  width: 6,
  color: 'black',
  lineCap: 'butt',
  lineJoin: 'arcs',
  dashArray: [2]
};

const property: PathProperties = { stroke: strokeProps, fill: 'none', transform: 'rotate(30)'}

GetPath(arrow, property);   // return string
// output:-
// <path d="..." stroke="black" stroke-width="6" stroke-linecap="butt" stroke-linejoin="arcs"
//    stroke-dasharray="[2]" fill="none" transform="rotate(30)" />`;

    const GetSvgExample = `import { GetSvg, Shape, ShapeTypes, PathProperties , BasicShape } from "@cimpress-technology/svg-path";

const arrow: ArrowShape = {
  type: ShapeTypes.Arrow,
  headWidth: 20,
  tailWidth: 20,
};

const strokeProps: Stroke = {
  width: 6,
  color: 'black',
  lineCap: 'butt',
  lineJoin: 'arcs',
  dashArray: [2]
};

const property: PathProperties = { stroke: strokeProps, fill: 'none', transform: 'rotate(30)'};
const viewbox: string = '0 0 200 200';

GetSvg(arrow, property, viewbox);   // return string
// output:-
// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path ... /></svg>
    `;

    const common = `type Shape = ArrowShape | CircularSpeechBubbleShape | SpeechBubbleShape | PolygonShape | StarburstShape;

enum ShapeTypes {
  Polygon = 'polygon',
  StarBurst = 'starBurst',
  Ellipse = 'ellipse',
  Rectangle = 'rectangle',
  Line = 'line',
  Arrow = 'arrow',
  DoubleSidedArrow = 'doubleSidedArrow',
  CircularSpeechBubble = 'circularSpeechBubble',
  SpeechBubble = 'speechBubble',
}

interface BasicShape {
  readonly type: ShapeTypes.Rectangle | ShapeTypes.Line | ShapeTypes.Ellipse;
  height: number;
  width: number;
}

interface ArrowShape {
  readonly type: ShapeTypes.Arrow | ShapeTypes.DoubleSidedArrow;
  headWidth: number;
  tailWidth: number;
}

interface CircularSpeechBubbleShape {
  readonly type: ShapeTypes.CircularSpeechBubble;
  pointerWidth: number;
  pointerHeight: number;
}

interface SpeechBubbleShape {
  readonly type: ShapeTypes.SpeechBubble;
  borderRadius: number;
  pointerPosition: number;
  pointerHeight: number;
}

interface StarburstShape {
  readonly type: ShapeTypes.StarBurst;
  numberOfPoints: number;
  height: number;
}

interface PolygonShape {
  readonly type: ShapeTypes.Polygon;
  numberOfSides: number;
  borderRadius: number;
}

interface PathProperties {
  stroke?: Stroke;
  fill?: string;
  transform?: string;
}

interface Stroke {
  width?: number;
  color?: string;
  lineCap?: 'butt' | 'round' | 'square';
  lineJoin?: 'arcs' | 'bevel' | 'miter' | 'miter-clip' | 'round';
  dashArray?: number[];
}`;

    const rangeValue = `PolygonShape {
      numberOfSides: 3 - 20,
      borderRadius: 0 - 100;
}

StarburstShape {
  numberOfPoints: 2 - 80;
  height: 0 - 100;
}

ArrowShape {
  headWidth: 0 - 100;
  tailWidth: 0 - 100;
}

CircularSpeechBubbleShape {
  pointerWidth: 0 - 100;
  pointerHeight: 0 - 100;
}

SpeechBubbleShape {
  borderRadius: 0 - 100;
  pointerPosition: 0 - 100;
  pointerHeight: 0 - 100;
}`;

    const getPathParams = `shape: shape for which you want to get path element string.

pathProperties: properties like stroke color, width you want to add to that path element.`;

    const getSvgParams = `shape: shape for which you want to get SVG element string.

pathProperties: properties like stroke color, width you want to add to that path element of this SVG.

viewBox: if provided it will add this to SVG, otherwise default viewBox will be added.`;

    const getPathDataParams = `shape: shape for which you want to calculate path data.`;

    return (
        <div>
            <p>
                After installing the Svg Path package, you're able to import the `@cimpress-technology/svg-path` package into your
                application. In order to use the new functions, you'll need to import it into your React application and use it. See sample
                code below
            </p>
            <div>
                <h4>All common enum, type and interface</h4>
                <pre className='code-section'>{common}</pre>
            </div>
            <div>
                <h4>Maximum Minimum property range of Shapes</h4>
                <pre className='code-section'>{rangeValue}</pre>
            </div>
            <div>
                <h4 id='GetPathData'>GetPathData</h4>
                <pre className='code-section'>GetPathData(shape: Shape)</pre>
                <p>
                    Parameters: <pre className='code-section'>{getPathDataParams}</pre>
                </p>
                <p>
                    Returns: <pre className='code-section'>string</pre>
                </p>
                <p>
                    Example usage: <CodeBlock>{GetPathDataExample}</CodeBlock>
                </p>
            </div>
            <div>
                <h4 id='Getpath'>GetPath</h4>
                <pre className='code-section'>GetPath(shape: Shape, pathProperties: PathProperties)</pre>
                <p>
                    Parameters: <pre className='code-section'>{getPathParams}</pre>
                </p>
                <p>
                    Returns: <pre className='code-section'>string</pre>
                </p>
                <p>
                    Example usage: <CodeBlock>{GetPathExample}</CodeBlock>
                </p>
            </div>
            <div>
                <h4 id='GetSvg'>GetSvg</h4>
                <pre className='code-section'>
                    GetSvg(shape: Shape | BasicShape, pathProperties: PathProperties, viewBox?: string | undefined)
                </pre>
                <p>
                    Parameters: <pre className='code-section'>{getSvgParams}</pre>
                </p>
                <p>
                    Returns: <pre className='code-section'>string</pre>
                </p>
                <p>
                    Example usage: <CodeBlock>{GetSvgExample}</CodeBlock>
                </p>
            </div>
        </div>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Layout, SmartShapes } from '@cimpress-technology/smart-shapes';
import { useContext } from 'react';

import { AuthContext } from '../../auth/AuthContext';

const widgetContainerStyle = css`
    padding: 10px;
    width: 350px;
    height: 650px;
    box-sizing: border-box;

    .smart-shapes__list-view {
        overflow-x: hidden;
        width: 100%;

        .smart-shapes--shape-types--separator {
            display: none;
        }

        .shape-type {
            box-shadow: 4px 4px 5px -3px rgba(0, 0, 0, 0.65);

            > img {
                height: 80%;
                width: 80%;
                left: calc(50% - 25%);
                top: calc(50% - 25%);
            }
        }

        .slider--progress-bar {
            top: 31px;
            box-shadow: 4px 4px 5px -3px rgba(0, 0, 0, 0.65);
        }
    }
    .slider--progress-head {
        border-radius: 30%;
        width: 22px;
        height: 22px;
    }
    .shape-options {
        overflow-x: hidden;
        overflow-y: auto;
        height: 140px;
    }
    .smart-shapes--preview-panel {
        background: none;
    }
    .shape-type-search {
        width: 98%;
    }
`;

const CustomStyle1 = () => {
    const { accessToken } = useContext(AuthContext);
    return (
        <div css={widgetContainerStyle}>
            <SmartShapes
                tenantId='SceneTestGroup'
                hostApp='SmartShapesExample'
                layout={Layout.PreviewOnTop}
                authConfig={{ authType: 'Bearer', value: accessToken }}
            />
        </div>
    );
};

export default CustomStyle1;

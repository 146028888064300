/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { pageContent, pageTitle, subSectionHeader } from 'screens/CommonStyles';
import { NpmUserSetup } from './NpmUserSetup';

export const PackageSetup: React.FC = () => {
    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Package integration setup</h1>
            <h3 css={subSectionHeader} id='Getting-started'>
                Getting started
            </h3>
            <NpmUserSetup />
        </div>
    );
};

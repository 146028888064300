/* eslint-disable @typescript-eslint/no-var-requires */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { CodeBlock } from '../../components/codeBlock/CodeBlock';
import { appTheme } from '../../theme';
import { subSectionHeader } from '../CommonStyles';
import CustomStyle1 from './CustomStyle1';

// eslint-disable-next-line import/no-webpack-loader-syntax
const customStyle1Text: string = require('!!raw-loader!./CustomStyle1').default;

const appStyle = css`
    > div {
        padding: 10px 2% 0 2%;
        border-top: dashed 2px ${appTheme.colors.cardBorder};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .code-block {
        width: 600px;
        background: none;
    }
`;

const CustomStyles = () => {
    return (
        <div css={appStyle}>
            <p>
                You can fully customize the style and layout of the SmartShapes widget by overriding CSS.
                <br />
                See a few examples below
            </p>

            <h4 css={subSectionHeader}>Style 1</h4>
            <div>
                <CustomStyle1 />
                <CodeBlock>{customStyle1Text}</CodeBlock>
            </div>
        </div>
    );
};

export default CustomStyles;

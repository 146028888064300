import { createContext } from 'react';

interface AuthContextProps {
    readonly error: string;
    readonly user?: {
        name: string;
        avatar: string;
        tenant: string;
    };
    readonly accessToken: string;
}

export const initialAuthContext: AuthContextProps = {
    error: '',
    user: undefined,
    accessToken: '',
};

export const AuthContext = createContext(initialAuthContext);

import axios from 'axios';

import { getRenderingFormatViewUrl } from '../utility/image.utility';
import { AuthConfig, ValidImageList, UploadItem } from '../admin.types';
import { MAX_LABEL_COUNT, URLs } from '../constants';

export async function getAllRenderingFormatViewUrl(validImageList: ValidImageList[], authConfig: AuthConfig) {
    const promises = validImageList.map(async (item) => {
        const url = await getRenderingFormatViewUrl(item.image, authConfig);
        return { id: item.id, url };
    });

    const urlList = await Promise.all(promises);
    const obj = {};
    urlList.forEach((item) => {
        obj[item.id] = item.url;
    });
    return obj;
}

export async function getAllTagList(newUploadList: UploadItem[], authConfig: AuthConfig) {
    const promises = newUploadList
        .filter((item) => item.isValidImageFormat && item.isValidImageSize && item.renderFormatUrl)
        .map(async (item) => {
            const tags = await getTagList(item.renderFormatUrl, authConfig);
            return { id: item.id, tags };
        });

    const tagList = await Promise.all(promises);
    const obj = {};
    tagList.forEach((item) => {
        obj[item.id] = item.tags;
    });
    return obj;
}

async function getTagList(url: string, authConfig: AuthConfig) {
    try {
        const requestObject = {
            url,
            maxLabels: MAX_LABEL_COUNT,
        };

        const { data } = await axios.post(URLs.SMART_TAG_LIST, requestObject, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
            },
        });
        if (data && Array.isArray(data)) {
            return data;
        }
        return [];
    } catch (err) {
        return [];
    }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { memo, useState, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import { jsx } from '@emotion/react';
import { Button, Label, Tag, Select, Checkbox } from '@cimpress/react-components';

import { AdminContext } from '../../context/AdminContext';
import { Collection, ApproveItem } from '../../admin.types';
import { AuthContext } from '../../context/AuthContext';
import { updateAsset, deleteAsset } from '../../services/asset.service';
import { getTrimmedTags } from '../../services/common.service';
import { getAllRenderingFormatViewUrl } from '../../services/upload.service';
import { formatBytes } from '../../utility/image.utility';
import { handleEnterKeyPress } from 'admin/utility/keyEvent.utility';
import { isEqualArrays } from 'admin/utility/common.utility';

interface ApproveProps {
    item: ApproveItem;
    selectedItem: { [key: string]: boolean };
    setSelectedItem: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
}

const Approve = ({ item, selectedItem, setSelectedItem, setIsChecked }: ApproveProps) => {
    const {
        approvedList,
        collectionList,
        renderingView,
        setRenderingView,
        setApprovedList,
        setIsRenderingViewLoading,
        uploadList,
        setUploadList,
    } = useContext(AdminContext);

    const { accessToken } = useContext(AuthContext);

    const [isEditTag, setIsEditTag] = useState<boolean>(false);
    const [isUpdateAssetError, setIsUpdateAssetError] = useState<boolean>(false);
    const [tagName, setTagName] = useState<string>('');
    const [tagList, setTagList] = useState<string[]>(item.tags);
    const [collection, setCollection] = useState<Collection>(item.collection);
    const [isUpdateLoader, setIsUpdateLoader] = useState<boolean>(false);
    const [isDeleteLoader, setIsDeleteLoader] = useState<boolean>(false);
    const [isDeleteAssetError, setIsDeleteAssetError] = useState<boolean>(false);
    const [isItemUpdated, setIsItemUpdated] = useState(false);

    const deleteTag = (index: number) => {
        const list = [...tagList];
        list.splice(index, 1);
        setTagList(list);
    };

    const handleRenderingView = async (approveItem: ApproveItem, approvedLists: ApproveItem[]) => {
        if (!approveItem) {
            setRenderingView({ fileId: '', fileUrl: '' });
            setApprovedList(approvedLists);
            return;
        }
        if (approveItem.renderFormatUrl) {
            setRenderingView({ fileId: approveItem.assetId, fileUrl: approveItem.renderFormatUrl });
            setApprovedList(approvedLists);
        } else {
            setIsRenderingViewLoading(true);
            const urlObj = await getAllRenderingFormatViewUrl([{ id: approveItem.assetId, image: approveItem.imageUrl }], {
                authType: 'Bearer',
                value: accessToken,
            });
            const list = approvedLists.map((listItem) => {
                if (listItem.assetId === approveItem.assetId) {
                    listItem.renderFormatUrl = urlObj[approveItem.assetId];
                }
                return listItem;
            });
            setApprovedList(list);
            setRenderingView({ fileId: approveItem.assetId, fileUrl: urlObj[approveItem.assetId] });
            setIsRenderingViewLoading(false);
        }
    };

    const imageClick = async () => {
        await handleRenderingView(item, approvedList);
    };

    const handleUpdate = async () => {
        setIsUpdateLoader(true);
        let isCollectionExistsInTags = false; // to handle for duplication of collection value in tags
        const tagsList = tagList.map((tag) => {
            const lowercaseTag = tag.toLowerCase();
            if (lowercaseTag === collection.value) isCollectionExistsInTags = true;
            return lowercaseTag;
        });
        // Adding collection as tag to allow search/filter by collection text
        if (!isCollectionExistsInTags) {
            tagsList.push(collection.value);
        }
        const isUpdatedAsset = await updateAsset(
            { authType: 'Bearer', value: accessToken },
            { collection, tagList: tagsList },
            item.assetId,
            item.imageUrl
        );
        if (isUpdatedAsset) {
            const list = approvedList.map((approvedItem) => {
                if (approvedItem.assetId === item.assetId) {
                    approvedItem.collection = collection;
                    approvedItem.tags = tagsList;
                }
                return approvedItem;
            });

            setApprovedList(list);
            setTagList(tagsList);
            setIsEditTag(false);
            const uploadedList = uploadList.map((uploadItem) => {
                if (uploadItem.id === item.assetId) {
                    uploadItem.collection = collection;
                    uploadItem.tags = tagsList;
                }
                return uploadItem;
            });
            setUploadList(uploadedList);
        }
        setIsUpdateLoader(false);
        setIsUpdateAssetError(!isUpdatedAsset);
    };

    const handleDelete = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Are you sure you want to delete file: ${item.assetName}?`)) {
            setIsDeleteLoader(true);
            const isDeletedAsset = await deleteAsset({ authType: 'Bearer', value: accessToken }, item.assetId);
            if (isDeletedAsset) {
                const list = approvedList.filter((approveItem) => approveItem.assetId !== item.assetId);
                const uploadedList = uploadList.filter((uploadItem) => uploadItem.id !== item.assetId);
                setUploadList(uploadedList);
                if (renderingView.fileId === item.assetId) {
                    await handleRenderingView(list[0], list);
                } else {
                    setApprovedList(list);
                }
            } else {
                setIsDeleteAssetError(!isDeletedAsset);
            }
            setIsDeleteLoader(false);
        }
    };

    useEffect(() => {
        setIsItemUpdated(item.collection.value !== collection.value || !isEqualArrays(tagList, item.tags));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collection, tagList]);
    const onSaveTag = () => {
        const tagSet = getTrimmedTags(tagName);
        if (tagSet && tagSet.length) {
            const uniqueTagSet = Array.from(new Set([...tagSet, ...tagList]));
            setTagList(uniqueTagSet);
            setTagName('');
        }
    };
    const onCancelTagBtn = () => {
        setTagName('');
        setIsEditTag(false);
    };
    const onCheckBoxChange = (e: any, payload: string) => {
        if (e.target.checked) {
            selectedItem[payload] = e.target.checked;
            const validSelectedKeysLength = Object.entries(selectedItem).filter((item) => !!item[1]).length;
            if (validSelectedKeysLength === approvedList.length) {
                setIsChecked(true);
            }
        } else {
            delete selectedItem[payload];
            setIsChecked(false);
        }

        setSelectedItem({ ...selectedItem });
    };

    return (
        <tr className={renderingView.fileId === item.assetId ? 'uploads-selected' : ''}>
            <td className='approve-select-column-div'>
                <Checkbox checked={!!selectedItem[item.assetId]} payload={item.assetId} onChange={onCheckBoxChange} />
            </td>
            <td>
                <div className='upload-image-div'>
                    <img className='upload-image' onClick={imageClick} src={item.imageUrl} alt={item.assetName} />
                </div>
            </td>
            <td>
                <div>
                    <Label
                        text={`${formatBytes(item.size)}`}
                        className={isUpdateAssetError ? 'upload-size-is-error' : 'upload-size-is-not-error'}
                    />

                    {(isDeleteAssetError || isUpdateAssetError) && <div className='upload-unsupported-file'>Something went wrong</div>}
                </div>
            </td>
            <td className='upload-tags-column'>
                <div>
                    <div className='upload-tags-div'>
                        {tagList.map((tag: string, index: number) => {
                            return <Tag key={tag} value={tag} removable onRemoveClick={() => deleteTag(index)} />;
                        })}
                    </div>
                    {isEditTag ? (
                        <div className='upload-tags-edit-root'>
                            <div>
                                <input
                                    className='upload-tags-edit-input'
                                    value={tagName}
                                    onChange={(e) => setTagName(e.target.value)}
                                    onKeyPress={handleEnterKeyPress(onSaveTag)}
                                />
                            </div>
                            <div className='upload-tag-btn-div'>
                                <Button
                                    variant='primary'
                                    className='upload-tags-btn add-tag-btn'
                                    onClick={onSaveTag}
                                    disabled={tagName.length === 0 || !tagName.trim()}
                                >
                                    Add
                                </Button>
                                <Button variant='primary' className='upload-tags-btn cancel-btn' onClick={onCancelTagBtn}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className='upload-tags-btn add-tags' onClick={() => setIsEditTag(true)}>
                            Add Tags
                        </div>
                    )}
                </div>
            </td>
            <td className='upload-collection-actions-column'>
                <Select label='Select a Collection' value={collection} options={collectionList} onChange={(e: any) => setCollection(e)} />
            </td>
            <td className='upload-collection-actions-column'>
                <div className='upload-edit-div'>
                    <Button disabled={isUpdateLoader || !isItemUpdated} variant='primary' size='sm' onClick={handleUpdate}>
                        {isUpdateLoader ? 'Updating...' : 'Update'}
                    </Button>

                    <Button disabled={isDeleteLoader} variant='primary' size='sm' onClick={handleDelete}>
                        {isDeleteLoader ? 'Deleting...' : 'Delete'}
                    </Button>
                </div>
            </td>
        </tr>
    );
};

export default memo(Approve);

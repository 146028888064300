/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-dark.css';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-tsx';
import cx from 'classnames';
import { scrollStyle } from './scrollStyle';
import { useBreakpointInfo } from '../useBreakpointInfo';

import { ReactComponent as CodeIcon } from './icons/code.svg';
import { ReactComponent as CopyIcon } from './icons/copy.svg';

const baseStyle = css`
    position: relative;

    button {
        display: none;
    }

    :hover {
        button {
            display: flex;
        }
    }
`;

const baseButtonStyle = css`
    display: flex;
    align-items: center;
    position: absolute;
    background: #73b1d1;
    border: none;
    outline: none;
    padding: 5px;

    :hover {
        background: #5491b0;
    }
    svg {
        margin-right: 0px;
        width: 16px;
        height: 16px;
    }
`;

const codeButtonStyle = css`
    ${baseButtonStyle}
    top: 15px;
    right: 105px;

    svg {
        margin-right: 10px;
    }
`;

const copyButtonStyle = css`
    ${baseButtonStyle}
    top: 15px;
    right: 22px;

    svg {
        margin-right: 10px;
    }
`;

const preStyle = css`
    max-height: 600px;
    overflow-y: auto;

    // style overrides on top of dark theme...
    background: #22485c !important;
    box-shadow: none !important;
    border: 3px solid #1481b8 !important;
    border-radius: 10px !important;

    code[class*='language-'] {
        text-shadow: none;
    }
`;

interface CodeBlockProps {
    children: string;
    className?: string;
}

export function CodeBlock({ children, className }: CodeBlockProps) {
    const [collapsed, setCollapsed] = useState(false);
    const { isSmallDevice } = useBreakpointInfo();

    useEffect(() => {
        Prism.highlightAll();
    }, [children, collapsed]);

    return (
        <div className={cx('code-block', className)} css={baseStyle}>
            <pre
                css={css`
                    ${preStyle}
                    ${!isSmallDevice && scrollStyle}
                `}
            >
                <code className='language-tsx'>{collapsed ? '// code hidden' : children}</code>
            </pre>
            <button css={codeButtonStyle} onClick={() => setCollapsed(!collapsed)}>
                <CodeIcon />
                Toggle code
            </button>
            <button css={copyButtonStyle} onClick={() => navigator.clipboard.writeText(children)}>
                <CopyIcon />
                Copy
            </button>
        </div>
    );
}

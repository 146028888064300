export const getTrimmedTags = (value?: string) =>
    value
        ?.trim()
        .replace(/;+/g, ';')
        .replace(/  +/g, ' ')
        .split(';')
        .filter((w) => w);

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt: string) {
        return `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`;
    });
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { scrollToTop } from 'utils';
import { SideNavEntries } from '../../components/sidePanel/SideNavEntries';
import { SideNavEntry } from '../../components/sidePanel/SideNavEntry';
import { SideNavSection } from '../../components/sidePanel/SideNavSection';
import { Documentation } from './Documentation';
import { PackageSetup } from './PackageSetup';
import { SvgPath } from './SvgPath';
import { SvgToCimdoc } from './SvgToCimdoc';

export function DocumentationIndex() {
    return (
        <SideNavEntries>
            <SideNavSection
                path='documentation'
                label='Smart Shape package'
                page={<Documentation />}
                onClick={() => scrollToTop('sidenav-container')}
            >
                <SideNavEntry path='#GettingStartedSmartShape' label='Getting started' />
                <SideNavEntry path='#step1-documentation' label='Step 1: Smart Shapes React Component' />
                <SideNavEntry path='#step2-documentation' label='Step 2: Provide custom theme to Smart Shapes widget' />
            </SideNavSection>
            <SideNavSection
                path='documentation/svg-path'
                label='Svg Path package'
                page={<SvgPath />}
                onClick={() => scrollToTop('sidenav-container')}
            >
                <SideNavEntry path='#GettingStartedSvgPath' label='Getting started' />
                <SideNavEntry path='#GetPathData' label='GetPathData' />
                <SideNavEntry path='#Getpath' label='GetPath' />
                <SideNavEntry path='#GetSvg' label='GetSvg' />
            </SideNavSection>
            <SideNavSection
                path='documentation/svg-to-cimdoc'
                label='Svg To cimdoc package'
                page={<SvgToCimdoc />}
                onClick={() => scrollToTop('sidenav-container')}
            >
                <SideNavEntry path='#GettingStartedSvgToCimdoc' label='Getting started' />
                <SideNavEntry path='#convertSvgToCimdoc' label='convertSvgToCimdoc' />
                <SideNavEntry path='#convertSvgToCimdocSync' label='convertSvgToCimdocSync' />
            </SideNavSection>
            <SideNavSection
                path='documentation/integration-setup'
                label='Package integration setup'
                page={<PackageSetup />}
                onClick={() => scrollToTop('sidenav-container')}
            />
        </SideNavEntries>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/react';
import { appTheme } from '../theme';

export const pageContent = css`
    font-family: 'Open Sans';
    text-align: left;
    box-sizing: border-box;
    padding: 32px;
    width: 100%;
    margin: 0;
    overflow-y: auto;
    color: ${appTheme.colors.primaryText};
    background-color: ${appTheme.colors.primaryBackground};
    min-height: 100%;

    ${appTheme.mediaQueries.M} {
        margin-top: 0px;
    }

    ${appTheme.mediaQueries.S} {
        width: 100%;
    }

    h2 {
        font-family: 'Gotham-Rounded-Book';
        font-weight: 600;
    }

    ul,
    ol,
    p {
        font-family: inherit;
        font-size: 15px;
    }

    .code-section {
        display: block;
        font-size: 15px;
        white-space: pre-wrap;
        text-align: left;
        padding: 5px;
        background: #eff3f5;
        border-color: #e1e6eb;
        border: 1px solid #ccc;
    }

    select {
        padding: 5px;
        color: ${appTheme.colors.primaryText};
        border: solid 1px ${appTheme.colors.primaryBorderColor};
    }
`;

export const pageTitle = css`
    font-family: 'Gotham-Rounded-Book';
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 48px;
    ${appTheme.mediaQueries.S} {
        margin-bottom: 25px;
    }
    color: ${appTheme.colors.highlight} !important;
`;

export const subSectionHeader = css`
    font-family: 'Gotham-Rounded-Book';
    font-size: 17px;
    font-weight: bold;
    margin-top: 50px;

    ${appTheme.mediaQueries.S} {
        margin-top: 20px;
        margin-bottom: 15px;
    }
`;

export const baseButtonStyle = css`
    border: solid 1px ${appTheme.colors.primary};
    border-radius: 2px;
    font-weight: 300;
    padding: 8px;
    cursor: pointer;
`;

export const primaryButtonStyle = css`
    ${baseButtonStyle}
    background: ${appTheme.colors.secondary};
    border: ${appTheme.colors.secondary};
    color: ${appTheme.colors.primaryBackground};

    &:hover:not([disabled]) {
        background: ${appTheme.colors.secondary};
        border-color: ${appTheme.colors.secondary};
    }

    &:active:not([disabled]) {
        background: ${appTheme.colors.secondary};
        border-color: ${appTheme.colors.secondary};
    }
`;

export const secondaryButtonStyle = css`
    ${baseButtonStyle}
    background: ${appTheme.colors.primaryBackground};
    border-color: ${appTheme.colors.secondary};
    color: ${appTheme.colors.secondary};

    &:hover:not([disabled]) {
        background: ${appTheme.colors.primaryBackground};
        border-color: ${appTheme.colors.secondary};
    }

    &:active:not([disabled]) {
        background: ${appTheme.colors.primaryBackground};
        border-color: ${appTheme.colors.secondary};
    }
`;

export const itemStyle = css`
    padding: 5px 0px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :hover {
        overflow: visible;
        white-space: inherit;
    }

    a {
        font-size: 15px;
        color: ${appTheme.colors.primaryText};
        text-decoration: none;

        :hover {
            color: ${appTheme.colors.primary};
        }
    }
`;

export const activeItemStyle = css`
    overflow: visible;
    white-space: inherit;
    a {
        font-weight: bold;
    }
`;

export const parentItemStyle = css`
    display: block;
    padding: 5px 0px;
    margin: 0px;

    font-size: 15px;
    color: ${appTheme.colors.primaryText};
    text-decoration: none;
    :hover {
        color: ${appTheme.colors.primary};
    }
`;

export const parentActiveItemStyle = css`
    font-weight: bold;
`;

export const baseTileStyle = css`
    box-sizing: border-box;
    border: 2px solid ${appTheme.colors.cardBorder};
    border-radius: 4px;
    background-color: ${appTheme.colors.cardBackground};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    display: inline-block;
    text-align: center;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 15px;
    position: relative;

    :hover {
        border: 1px solid ${appTheme.colors.highlight};
    }
`;

export const tableStyle = css`
    border-spacing: 0;
    font-size: 15px;

    td,
    th {
        padding: 10px 15px;
        border-bottom: 2px solid ${appTheme.colors.cardBorder};
    }

    tr:nth-of-type(even) {
        background-color: ${appTheme.colors.codeBlock};
    }

    th {
        font-weight: bold;
    }
`;

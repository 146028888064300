import { Fragment, memo, useContext, useState } from 'react';
import { Button, Checkbox } from '@cimpress/react-components';

import Upload from './Upload';
import Approve from './Approve';
import { AdminContext } from '../../context/AdminContext';
import { deleteSelectedAssets } from '../../services/asset.service';
import { AuthContext } from '../../context/AuthContext';
import { getAllRenderingFormatViewUrl } from '../../services/upload.service';
import { ApproveItem } from '../../admin.types';

import { UPLOADS_TAB, APPROVED_TAB, UPLOAD_HEADER_LIST, APPROVE_HEADER_LIST } from '../../constants';
import { Pagination } from '../pagination/Pagination';
import useFetchAssets from '../../useFetchAssets';

const getTableHeaderCss = (tab: string) => {
    switch (tab) {
        case 'COLLECTION':
        case 'ACTIONS':
            return 'upload-collection-actions-column';
        case 'TAGS':
            return 'upload-tags-column';
        default:
            return '';
    }
};

const UploadList = ({ searchValue }: { searchValue: string }) => {
    const {
        uploadList,
        approvedList,
        selectedTab,
        nextOffset,
        prevOffsets,
        setPrevOffsets,
        setUploadList,
        setApprovedList,
        renderingView,
        setRenderingView,
    } = useContext(AdminContext);
    const { accessToken } = useContext(AuthContext);
    const { queryAssets } = useFetchAssets();

    const [selectedItem, setSelectedItem] = useState({});
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleOnClickNext = () => {
        nextOffset && setPrevOffsets([...prevOffsets, nextOffset]);
        queryAssets(searchValue, nextOffset);
    };

    const handleOnClickPrev = () => {
        prevOffsets.splice(-1);
        const previousOffset = prevOffsets[prevOffsets.length - 1];
        queryAssets(searchValue, previousOffset);
    };

    const onCheckBoxChange = (e: any) => {
        setIsChecked(e.target.checked);
        let selectedObject = {};
        if (e.target.checked) {
            selectedObject = approvedList.reduce<{ [key: string]: boolean }>((acc, item) => {
                acc[item.assetId] = e.target.checked;
                return acc;
            }, {});
        }
        setSelectedItem(selectedObject);
    };
    const deleteSelectedUploads = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to delete all selected uploads?')) {
            setIsDeleting(true);
            const assetIdList = approvedList.reduce<string[]>((assetIdList, item) => {
                if (selectedItem[item.assetId]) {
                    assetIdList.push(item.assetId);
                }
                return assetIdList;
            }, []);
            if (assetIdList.length) {
                const { error, assetObj } = await deleteSelectedAssets({ authType: 'Bearer', value: accessToken }, assetIdList);

                if (!error && assetObj) {
                    let list: ApproveItem[] = approvedList.filter((item) => (assetObj[item.assetId] ? !assetObj[item.assetId] : true));
                    if (assetObj[renderingView.fileId] || !list.length) {
                        let obj = {
                            fileId: '',
                            fileUrl: '',
                        };
                        if (list.length) {
                            if (list[0].renderFormatUrl) {
                                obj = { fileId: list[0].assetId, fileUrl: list[0].renderFormatUrl };
                            } else {
                                const urlObj = await getAllRenderingFormatViewUrl([{ id: list[0].assetId, image: list[0].imageUrl }], {
                                    authType: 'Bearer',
                                    value: accessToken,
                                });
                                const newList = list.map((listItem) => {
                                    if (listItem.assetId === list[0].assetId) {
                                        listItem.renderFormatUrl = urlObj[list[0].assetId];
                                    }
                                    return listItem;
                                });
                                list = newList;
                                obj = { fileId: list[0].assetId, fileUrl: urlObj[list[0].assetId] };
                            }
                        }
                        setRenderingView(obj);
                    }
                    setApprovedList(list);
                    setSelectedItem({});
                    setIsChecked(false);
                    const uploadedList = uploadList.filter((uploadItem) => (assetObj[uploadItem.id] ? !assetObj[uploadItem.id] : true));
                    setUploadList(uploadedList);
                }
            }
            setIsDeleting(false);
        }
    };

    const getUploadElements = () => {
        return (
            <Fragment>
                <div className='upload-list-button-div'>
                    <Button variant='primary' size='sm' onClick={() => setUploadList([])}>
                        Clear Current Uploads
                    </Button>
                </div>

                <table>
                    <thead>
                        <tr>
                            {UPLOAD_HEADER_LIST.map((name) => {
                                return (
                                    <th key={name} className={getTableHeaderCss(name)}>
                                        {name}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {uploadList.map((item) => {
                            return <Upload key={item.id} item={item} />;
                        })}
                    </tbody>
                </table>
            </Fragment>
        );
    };

    const getApprovedElements = () => {
        return (
            <Fragment>
                <div className='upload-list-button-div'>
                    <Button
                        variant='primary'
                        size='sm'
                        disabled={isDeleting || !Object.keys(selectedItem).length}
                        onClick={deleteSelectedUploads}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete Selected Uploads'}
                    </Button>
                </div>
                <table>
                    <thead>
                        <tr>
                            {APPROVE_HEADER_LIST.map((name, index) => {
                                return (
                                    <th key={name} className={getTableHeaderCss(name)}>
                                        {index === 0 ? (
                                            <div className='approve-select-column-div'>
                                                <Checkbox checked={isChecked} onChange={onCheckBoxChange} />
                                            </div>
                                        ) : (
                                            name
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {approvedList.map((item) => {
                            return (
                                <Approve
                                    key={item.assetId}
                                    item={item}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    setIsChecked={setIsChecked}
                                />
                            );
                        })}
                    </tbody>
                </table>
                <Pagination
                    hasNextPage={!!nextOffset}
                    hasPrevPage={prevOffsets.length > 0}
                    onClickNext={handleOnClickNext}
                    onClickPrev={handleOnClickPrev}
                />
            </Fragment>
        );
    };
    const obj = {
        [UPLOADS_TAB]: getUploadElements,
        [APPROVED_TAB]: getApprovedElements,
    };
    return <Fragment>{obj[selectedTab]()}</Fragment>;
};
export default memo(UploadList);

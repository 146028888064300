interface Props {
    hidden: boolean;
}

/**
 * This is the component wrapper for image widgets.
 * The widgets for uploading images, drag and dropping images, adding placeholder images, and drag and dropping placeholder images are found here.
 */
export default function ImageTab({ hidden }: Props) {
    return (
        <div className={`content-panel${hidden ? ' content-panel--hidden' : ''}`}>
            <div className='content-panel__title'>Image</div>
            <div className='content-panel__add-button'>
                <div className='dcl-widget-upload-button' />
            </div>
            <div className='dcl-widget-upload-list' />
            <div className='content-panel__title placeholder-label'>Placeholder</div>
            <div className='dcl-widget-placeholder-list' />
        </div>
    );
}

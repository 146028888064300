import React, { useState, createContext } from 'react';

import { ProviderProps, UploadItem, ApproveItem, RenderingView, Collection } from '../admin.types';
import { initialAdminContext, initialRenderingView, UPLOADS_TAB } from '../constants';

export const AdminContext = createContext(initialAdminContext);

export function AdminProvider({ children }: ProviderProps) {
    const [uploadList, setUploadList] = useState<UploadItem[]>([]);
    const [renderingView, setRenderingView] = useState<RenderingView>(initialRenderingView);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [collectionList, setCollectionList] = useState<Collection[]>([]);
    const [approvedList, setApprovedList] = useState<ApproveItem[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>(UPLOADS_TAB);
    const [isFetchingApprovedList, setFetchingApprovedList] = useState<boolean>(false);
    const [isRenderingViewLoading, setIsRenderingViewLoading] = useState<boolean>(false);
    const [prevOffsets, setPrevOffsets] = useState<string[]>([]);
    const [nextOffset, setNextOffset] = useState<string | undefined>(undefined);
    return (
        <AdminContext.Provider
            value={{
                uploadList,
                setUploadList,
                renderingView,
                setRenderingView,
                isUploading,
                setIsUploading,
                collectionList,
                setCollectionList,
                approvedList,
                setApprovedList,
                selectedTab,
                setSelectedTab,
                isFetchingApprovedList,
                setFetchingApprovedList,
                isRenderingViewLoading,
                setIsRenderingViewLoading,
                prevOffsets,
                setPrevOffsets,
                nextOffset,
                setNextOffset,
            }}
        >
            {children}
        </AdminContext.Provider>
    );
}

import { Collection, AdminContextType, AuthContextType, RenderingView } from './admin.types';

export const collectionData: Collection[] = [
    { value: 'animal', label: 'Animal' },
    { value: 'shapes', label: 'Shapes' },
];
export const MAX_FILE_SIZE_LIMIT = 104857600; // Size in bytes(100 mb)

export const UPLOADS_TAB = 'Uploads';
export const APPROVED_TAB = 'Approved';

export const initialAdminContext: AdminContextType = {
    uploadList: [],
    setUploadList: () => ({}),
    renderingView: {
        fileId: '',
        fileUrl: '',
    },
    setRenderingView: () => ({}),
    isUploading: false,
    setIsUploading: () => ({}),
    collectionList: [],
    setCollectionList: () => ({}),
    approvedList: [],
    setApprovedList: () => ({}),
    selectedTab: UPLOADS_TAB,
    setSelectedTab: () => ({}),
    isFetchingApprovedList: false,
    setFetchingApprovedList: () => ({}),
    setIsRenderingViewLoading: () => ({}),
    isRenderingViewLoading: false,
    prevOffsets: [],
    setPrevOffsets: () => ({}),
    nextOffset: undefined,
    setNextOffset: () => ({}),
};
export const initialRenderingView: RenderingView = {
    fileId: '',
    fileUrl: '',
};

export const initialAuthContext: AuthContextType = {
    error: '',
    user: undefined,
    accessToken: '',
};

const devShapesApiBaseUrl = 'https://dev.shapesapi.designassets.cimpress.io/v0';
const shapesApiBaseUrl = 'https://shapesapi.designassets.cimpress.io/v0';
export const devIconFetchAppId = 'flouFWFYXVrHeyIePnHpAevqjjWqY1Kz';
export const iconFetchAppId = 'Y4jxfLLmGzXZpjPkPAJEmLfUVAMRgBFT';
export const IsDevDeployment = process.env.REACT_APP_DEPLOYMENT_ENV === 'development';

export const URLs = {
    SHERBERT_QUERY_URL: 'https://dev.shapesapi.designassets.cimpress.io/v0/library:query',
    SHERBERT_BASE: 'https://sherbert-api.storage.cimpress.io/v1',
    SMART_TAG_LIST: `${IsDevDeployment ? devShapesApiBaseUrl : shapesApiBaseUrl}/detectLabels`,
    UDS_DOCUMENT_API: 'https://uds.documents.cimpress.io/v3/documents',
    SETTINGS_API: 'https://settings.cimpress.io/v1/settings',
    SHERBERT_QUERY_POST_URL: 'https://sherbert-api.storage.cimpress.io/v1/assets:query',
};

export const COAM_GROUP_ID = 138434;

export const UPLOAD_HEADER_LIST = ['IMAGE', 'SIZE', 'TAGS', 'COLLECTION', 'ACTIONS'];
export const APPROVE_HEADER_LIST = ['', 'IMAGE', 'SIZE', 'TAGS', 'COLLECTION', 'ACTIONS'];
export const ACCEPTED_FILE_FORMATS = ['.svg'];
export const MAX_LABEL_COUNT = 5;
export const FONT_REPOSITORY_URL = 'https://fonts.documents.cimpress.io/v1/repositories/9f441c3e-3041-4f04-a47c-4c299f641083/Published';

export const SETTINGS_RESOURCE_TYPE = 'merchants';
export const SETTINGS_RESOURCE_ID = IsDevDeployment ? 'DesignAssetsTestMerchant' : 'DesignAssetsMerchant';
export const SETTINGS_NAME = 'smartShapesIconLibraryCollections';
export const SETTINGS_COLLECTION_ASSET_ID = IsDevDeployment
    ? '53e4e9a4-23ac-4af9-a432-6035227a05c2'
    : 'bb1b7759-25b6-4820-9d64-d45dfcc5ef27';

export const INSTRUCTIONS_URL_SIZE_LIMIT = 7000;

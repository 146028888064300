import { Fragment, useEffect, useContext, useState } from 'react';
import { NavTab, NavTabItem, shapes } from '@cimpress/react-components';

import { UPLOADS_TAB, APPROVED_TAB } from '../constants';
import AllUploads from './uploads/AllUploads';
import { AdminContext } from '../context/AdminContext';
import { AuthContext } from '../context/AuthContext';
import { ReactComponent as EmptySearchIcon } from '../icons/emptySearchIcon.svg';
import useFetchAssets from 'admin/useFetchAssets';
import { SearchIcons } from './searchIcons/SearchIcons';

const Tabs = () => {
    const {
        uploadList,
        approvedList,
        selectedTab,
        setSelectedTab,
        setRenderingView,
        isFetchingApprovedList,
        setPrevOffsets,
        setNextOffset,
    } = useContext(AdminContext);
    const { accessToken } = useContext(AuthContext);
    const { queryAssets } = useFetchAssets();
    const { Spinner } = shapes;

    const [searchValue, setSearchValue] = useState('');

    const onSearch = () => {
        setPrevOffsets([]);
        setNextOffset(undefined);
        queryAssets(searchValue);
    };

    const onClearSearch = () => {
        setPrevOffsets([]);
        setNextOffset(undefined);
        setSearchValue('');
        queryAssets('');
    };

    useEffect(() => {
        if (accessToken && selectedTab === APPROVED_TAB) queryAssets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, accessToken]);

    const getTabList = (tab: string) => {
        switch (tab) {
            case UPLOADS_TAB:
                return uploadList;
            case APPROVED_TAB:
                return approvedList;
            default:
                return [];
        }
    };

    return (
        <Fragment>
            <Fragment>
                <NavTab>
                    <NavTabItem active={selectedTab === UPLOADS_TAB}>
                        <button
                            onClick={() => {
                                setRenderingView({
                                    fileId: uploadList.length ? uploadList[0].id : '',
                                    fileUrl: uploadList.length ? uploadList[0].renderFormatUrl : '',
                                });
                                setSelectedTab(UPLOADS_TAB);
                                setSearchValue('');
                            }}
                        >
                            {UPLOADS_TAB}
                        </button>
                    </NavTabItem>
                    <NavTabItem active={selectedTab === APPROVED_TAB}>
                        <button
                            onClick={() => {
                                setSelectedTab(APPROVED_TAB);
                                setPrevOffsets([]);
                                setNextOffset(undefined);
                            }}
                        >
                            {APPROVED_TAB}
                        </button>
                    </NavTabItem>
                </NavTab>
                {selectedTab === APPROVED_TAB && (
                    <SearchIcons
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        onSearch={onSearch}
                        onClearSearch={onClearSearch}
                    />
                )}
                {!isFetchingApprovedList ? (
                    getTabList(selectedTab)?.length ? (
                        <AllUploads searchValue={searchValue} />
                    ) : (
                        <div className='tabs-no-results-div'>
                            <EmptySearchIcon className='tabs-no-results' />
                        </div>
                    )
                ) : (
                    <div className='tabs-spinner'>
                        <Spinner />
                    </div>
                )}
            </Fragment>
        </Fragment>
    );
};

export default Tabs;

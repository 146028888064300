import { SideNavEntries } from '../../components/sidePanel/SideNavEntries';
import { SideNavEntry } from '../../components/sidePanel/SideNavEntry';
import { SideNavSection } from '../../components/sidePanel/SideNavSection';
import CustomStyles from '../customStyles/CustomStyles';
import Demo from './Demo';
import { InteractiveDemo } from './InteractiveDemo';

export function DemoIndex() {
    return (
        <SideNavEntries>
            <SideNavSection path='demo' label='Demo' page={<Demo />}>
                <SideNavEntry path='interactive' label='Interactive'>
                    <InteractiveDemo />
                </SideNavEntry>
                <SideNavEntry path='custom-styles' label='Custom styles'>
                    <CustomStyles />
                </SideNavEntry>
            </SideNavSection>
        </SideNavEntries>
    );
}

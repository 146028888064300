/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { ReactElement, Children, useState, ReactNode } from 'react';
import { Switch, Route } from 'react-router-dom';

import { pageContent, pageTitle as pageTitleStyle } from '../../screens/CommonStyles';
import { appTheme } from '../../theme';
import { SidePanel } from './SidePanel';
import { SideNavEntryProps } from './SideNavEntry';
import { SideNavSectionProps, SideNavSection } from './SideNavSection';
import { useBreakpointInfo } from '../useBreakpointInfo';

const pageStyle = css`
    height: 100%;
    display: flex;
    order: 2;
`;

const sidePanelContainerStyle = css`
    width: 20%;
    min-width: 300px;
    ${appTheme.mediaQueries.M} {
        width: 0;
        min-width: 0;
    }
    ${appTheme.mediaQueries.S} {
        width: 0;
        min-width: 0;
    }
`;

const detailsPanelStyle = css`
    order: 2;
    background-color: #ffffff;
    width: 80%;
    overflow: auto;
    scroll-behavior: smooth;
    ${appTheme.mediaQueries.M} {
        width: 100%;
    }
`;

const overlayStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
`;

const sideMenuIcon = css`
    display: inline-block;
    cursor: pointer;
    padding: 20px;
    border: none;
    background: inherit;
`;

const navIcon = css`
    background: ${appTheme.colors.headerText};
    display: block;
    height: 2px;
    position: relative;
    width: 1.625rem;

    &:before,
    &:after {
        background: ${appTheme.colors.headerText};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
    }

    &:before {
        top: 7px;
    }

    &:after {
        top: -7px;
    }
`;

function isSideNavSection(element: ReactElement<any>): element is ReactElement<SideNavSectionProps> {
    return element.type === SideNavSection;
}

interface SideNavEntriesProps {
    children:
        | ReactElement<SideNavEntryProps>[]
        | ReactElement<SideNavEntryProps>
        | ReactElement<SideNavSectionProps>[]
        | ReactElement<SideNavSectionProps>;
}

export function SideNavEntries({ children }: SideNavEntriesProps) {
    const { isSmallDevice, isMediumDevice } = useBreakpointInfo();
    const isCompactLayout = isSmallDevice || isMediumDevice;
    const [isSidePanelExpanded, setIsSidePanelExpanded] = useState(false);

    const routes: { pageTitle?: string; page: ReactNode | ReactNode[]; path: string }[] = [];
    Children.forEach(children, (child) => {
        if (isSideNavSection(child)) {
            routes.push({
                page: child.props.page,
                path: `/${child.props.path}`,
            });

            // iterate over each entry making route with section part of path
            Children.forEach(child.props.children, (sideNavEntry) => {
                if (sideNavEntry && sideNavEntry.props.path) {
                    routes.push({
                        pageTitle: sideNavEntry.props.label,
                        page: sideNavEntry.props.children,
                        path: `/${child.props.path}/${sideNavEntry.props.path}`,
                    });
                }
            });
        }
    });

    const sidePanel =
        !isCompactLayout || isSidePanelExpanded ? (
            <SidePanel
                isCloseable={isCompactLayout}
                onClose={() => setIsSidePanelExpanded(false)}
                position={isCompactLayout ? 'absolute' : 'relative'}
            >
                {children}
            </SidePanel>
        ) : null;

    let overlay;
    if (isCompactLayout && isSidePanelExpanded) {
        overlay = (
            <div
                css={overlayStyle}
                onClick={() => {
                    setIsSidePanelExpanded(false);
                }}
            />
        );
    }
    return (
        <div css={pageStyle}>
            <div css={detailsPanelStyle} id='sidenav-container'>
                {isCompactLayout && (
                    <button
                        css={sideMenuIcon}
                        onClick={() => {
                            setIsSidePanelExpanded(!isSidePanelExpanded);
                        }}
                    >
                        <span css={navIcon} />
                    </button>
                )}
                <Switch>
                    {routes.reverse().map((route) => {
                        const { page, pageTitle, path } = route;
                        if (pageTitle) {
                            return (
                                <Route path={path} key={path}>
                                    <div css={pageContent}>
                                        <h1 css={pageTitleStyle}>{pageTitle}</h1>
                                        {page}
                                    </div>
                                </Route>
                            );
                        }

                        return (
                            <Route path={path} key={path}>
                                {page}
                            </Route>
                        );
                    })}
                </Switch>
            </div>
            {overlay}
            <div css={sidePanelContainerStyle}>{sidePanel}</div>
        </div>
    );
}

import { useRef } from 'react';
import { Button } from '@cimpress/react-components';

import './fileUploadButton.css';
import { FileButtonProps } from '../../admin.types';

export function FileUploadButton({ children, type, accept, onFileUpload }: FileButtonProps) {
    const referenceFileInput = useRef<HTMLInputElement>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (event: any) => {
        onFileUpload(event.target.files);
        if (referenceFileInput && referenceFileInput.current) {
            referenceFileInput.current.value = '';
        }
    };
    return (
        <div className='upload-btn-wrapper'>
            <Button variant={type}>
                <input type='file' ref={referenceFileInput} onChange={onChange} multiple accept={accept && accept.join(',')} />
                {children}
            </Button>
            <div className='upload-btn-text-div'>or drag svg in here</div>
        </div>
    );
}

import { useContext } from 'react';

import { AuthContext } from './AuthContext';

interface CurrentUserProps {
    error: string;
    user?: {
        name: string;
        avatar: string;
        tenant: string;
    };
    accessToken: string;
}

export function useCurrentUser(): CurrentUserProps {
    const { error, user, accessToken } = useContext(AuthContext);

    return {
        error,
        user,
        accessToken,
    };
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { appTheme } from '../../theme';

const inlineMenuStyle = css`
    padding: 0;
    list-style: none;
    margin: 0;

    display: flex;
    flex: 2;
    flex-direction: row;

    > li {
        flex: 1;
        margin: 0 10px;
        max-width: 200px;
        ${appTheme.mediaQueries.M} {
            max-width: 100%;
        }
        &:hover,
        &:focus {
            border-bottom: 2px solid ${appTheme.colors.primary};
            border-top: 2px solid transparent;

            ${appTheme.mediaQueries.M} {
                border: none;
            }
        }

        a,
        a:visited {
            color: ${appTheme.colors.primary};
            display: block;
            width: 100%;
            height: 100%;
            text-decoration: none;
            box-sizing: border-box;
        }

        a span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            user-select: none;
        }
    }
`;

function getDrawerMenuStyleOverrides(isOpen: boolean) {
    return css`
        flex-direction: column;
        background-color: white;
        position: absolute;
        top: ${isOpen ? '60px' : '0'};
        left: 0;
        width: 100%;
        z-index: 1;
        transition: 0.2s ease-in-out;
        transform: translateY(${!isOpen ? '-125%' : '0%'});
        box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
        > li {
            height: 75px;
        }
    `;
}

export interface NavigationMenuProps {
    menuItems: {
        path: string;
        label: string;
    }[];
    selectedMenu: string;
    mode: 'inline' | 'drawer';
    isOpen?: boolean;
    onSelect: (label: string) => void;
}

export function NavigationMenu({ menuItems, selectedMenu, mode, isOpen = false, onSelect }: NavigationMenuProps) {
    const cssStyles = [inlineMenuStyle];
    if (mode === 'drawer') {
        cssStyles.push(getDrawerMenuStyleOverrides(isOpen));
    }

    return (
        <ul css={cssStyles}>
            {menuItems.map(({ path, label }) => (
                <li
                    key={label}
                    css={css`
                        border-bottom: ${label === selectedMenu ? '2px solid ' + appTheme.colors.primary : 'none'};
                        border-top: ${label === selectedMenu ? '2px solid transparent' : 'none'};
                    `}
                >
                    <Link to={path} onClick={() => onSelect(label)}>
                        <span>{label}</span>
                    </Link>
                </li>
            ))}
        </ul>
    );
}

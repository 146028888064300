import { CssLoader } from '@cimpress/react-components';

import { AdminProvider } from '../context/AdminContext';
import { AuthProvider } from '../context/AuthContext';
import Admin from './Admin';
import './admin.css';

const App = () => {
    return (
        <AuthProvider>
            <AdminProvider>
                <CssLoader>
                    <Admin />
                </CssLoader>
            </AdminProvider>
        </AuthProvider>
    );
};

export default App;

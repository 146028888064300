/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ReactNode } from 'react';
import { tableStyle } from 'screens/CommonStyles';

export interface Props {
    name: string;
    required: boolean;
    description: string;
    content: ReactNode;
}

export interface PropsDocumentationProps {
    props: Props[];
}

export function PropsDocumentation({ props }: PropsDocumentationProps) {
    return (
        <div>
            <div>
                <table css={tableStyle}>
                    <thead>
                        <tr>
                            <th>Prop</th>
                            <th>Required?</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.map((prop) => (
                            <tr key={prop.name}>
                                <td>
                                    <a href={`#${encodeURIComponent(prop.name)}`}>
                                        <code>{prop.name}</code>
                                    </a>
                                </td>
                                <td>{prop.required ? 'True' : 'False'}</td>
                                <td>{prop.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <article>
                {props.map((prop) => (
                    <section key={prop.name}>
                        <h4 id={`${encodeURIComponent(prop.name)}`}>{prop.name}</h4>
                        {prop.content}
                    </section>
                ))}
            </article>
        </div>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { itemStyle, activeItemStyle } from '../../screens/CommonStyles';

export interface SideNavEntryProps {
    children?: ReactNode | ReactNode[];
    onClick?: () => void;
    path?: string;
    fullPath?: string;
    url?: string;
    label: string;
}

export function SideNavEntry({ path, fullPath, url, label, onClick }: SideNavEntryProps) {
    const location = useLocation();

    const isActive = !!((path && location.pathname.includes(path)) || (path && location.hash.includes(path)));

    return (
        <li css={[itemStyle, isActive ? activeItemStyle : '']}>
            {url && <a href={url}>{label}</a>}
            {path && fullPath && (
                <HashLink to={fullPath} onClick={onClick ?? undefined}>
                    {label}
                </HashLink>
            )}
        </li>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment, useState, useEffect } from 'react';
import Canvases from './Canvases';
import { getDesignerConfiguration } from './designer.config';
import HeadTags from './HeadTags';
import Tabs from './Tabs';
import Toolbar from './Toolbar';
import { Designer } from './type.designer';

import './designer.css';

interface Props {
    currentLocale: string;
}

declare global {
    interface Window {
        designer?: Designer;
    }
}

function removeFromDom(selector: string) {
    const elem = document.querySelector(selector);
    elem && elem.parentNode && elem.parentNode.removeChild(elem);
}

const loadDesigner = (designerVersion: string): Promise<Designer> =>
    new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.src = `https://static.designer.cimpress.io/${designerVersion}/designer.min.js`;
        document.head.append(script);

        script.onload = () => {
            window.designer ? resolve(window.designer) : reject(new Error('designer could not load'));
        };

        script.onerror = (error) => {
            reject(error);
        };
    });

const settings = {
    apiKey: '59d5ffeba0d70ae05f8ea48c73653686c4abc0a671e4876061920cf64a5d360d',
    designerVersion: 'latest',
    fontRepository: '9f441c3e-3041-4f04-a47c-4c299f641083/Published',
    fonts: null,
    uploadTenant: 'default',
};
const product = {
    sku: 'VIP-12345',
};
const documentReferenceUrl = undefined;
const cimDoc = undefined;
const surfaceSpecifications = undefined;
const designerMode = 'templateEditor';
const tabsHidden = true;
const switcherHidden = true;

const Editor = ({ currentLocale }: Props) => {
    const [designer, setDesigner] = useState<Designer>();

    useEffect(() => {
        loadDesigner('latest').then((windowDesigner: Designer) => {
            windowDesigner
                .start(
                    getDesignerConfiguration(
                        settings,
                        product,
                        documentReferenceUrl,
                        cimDoc,
                        surfaceSpecifications,
                        designerMode,
                        tabsHidden,
                        switcherHidden,
                        currentLocale
                    )
                )
                .then(() => {
                    setDesigner(windowDesigner);
                    console.log('designer loaded');
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((e: any) => {
                    console.error(`Designer failed to start. ${JSON.stringify(e)} ${e}`);
                });
        });

        return () => {
            delete window.designer;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => () => {
            removeFromDom('#dcl-upload-form');
            removeFromDom('.dcl-popper-overlay');
            removeFromDom('.dcl-message-overlay');
            removeFromDom('.dcl-contextual-toolbar');
        },
        []
    );

    return (
        <Fragment>
            <HeadTags designerVersion='latest' />
            <div className='designer'>
                <Toolbar />
                <Tabs designer={designer} tenantId='testTenant' locale='en-US' />
                <Canvases />
                <div className='dcl-widget-canvas-switcher' />
            </div>
            <div className='footer__section'>
                <div className='dcl-widget-preview-document' />
                <div className='dcl-widget-canvas-zoom' />
                <div className='dcl-widget-canvas-history' />
            </div>
        </Fragment>
    );
};

export default Editor;

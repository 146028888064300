export const isEqualArrays = (array1: string[], array2: string[]): boolean => {
    if (array1.length !== array2.length) {
        return false;
    }
    const arraySet = new Set(array1);
    for (let i = 0; i < array2.length; i++) {
        if (!arraySet.has(array2[i])) {
            return false;
        }
    }
    return true;
};

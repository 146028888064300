import { memo } from 'react';
import { Helmet } from 'react-helmet';

/**
 * This component handles loading and unloading the designer.js and designer.css files from the DOM
 * Wrapping the component in memo() makes it a pureComponent. react-helmet v5 has a bug that requires this to be pure.
 * https://github.com/nfl/react-helmet/issues/373
 */
interface Props {
    designerVersion: string;
}

export default memo(({ designerVersion }: Props) => (
    <Helmet>
        <link href={`https://static.designer.cimpress.io/${designerVersion}/designer.css`} rel='stylesheet' />
    </Helmet>
));

interface Margins {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

interface ZoomStrategy {
    resizeEnabled: boolean;
    initialWidth: number;
    initialHeight: number;
    initialZoom: number;
    maxZoom: number;
    minZoom: number;
    zoomIncrement: number;
}

function formatConfiguration(
    uploadTenant: string | undefined,
    apiKey: string | undefined,
    fontRepository: string | undefined,
    fontListString: string | undefined,
    extraMargins: Margins,
    zoomStrategy: ZoomStrategy,
    locale?: string
) {
    const configuration = {
        localization: {
            language: locale,
        },
        customer: {
            id: '',
        },
        core: {
            merchantId: uploadTenant || 'default',
            authentication: {
                apiKey: apiKey || '',
            },
            snapping: {
                rotation: {
                    showLine: true,
                    snapToOther: true,
                },
            },
            rotation: {
                showDegrees: true,
            },
            engraving: {
                embedColor: false,
                color: '#FFFFFF',
            },
            items: {
                text: {
                    // The text configuration is where text properties and interactions are configured for Designer.
                    multiline: true,
                    inlineEdit: true,
                    resizeStrategy: 'scaleOnCorners',
                },
                image: {
                    // The image configuration is where image properties and interactions are configured for Designer.
                    knockoutable: true, // A boolean indicating whether images can have their backgrounds removed
                },
            },
        },
        ui: {
            canvas: {
                extraMargins,
                enabledCanvas: 1,
                visibleCanvas: 1,
                lazyLoadHiddenCanvases: true, // Products like calendars, it reduces the load time that a customer experiences significantly
                chromes: {
                    label: {
                        enabled: true, // Showing surface name near design area
                    },
                    ruler: {
                        enabled: true, // Adding a ruler to your design area can assist your customers with positioning and sizing
                    },
                    margins: {
                        bleed: {
                            fillAroundSVG: true, // if true will fill the area surrounding the SVG instead of the SVG itself
                            fillOpacity: 0.5, // specifies the opacity surrounding the svg if 'fillAroundSVG' is true, otherwise is svg opacity
                            opacity: 1, // opacity of the entire svg
                        },
                        trim: {
                            enabled: true,
                        },
                    },
                    outsideBoundsHighlight: {
                        // highlighted when a Text, Image, or Shape Item are out of the Bleed
                        enabled: true,
                    },
                    infoIndicators: {
                        // Indicators of Bleed, Safety, Trim
                        margins: {
                            trim: {
                                enabled: false, // disabling as some build-a-sign's templates are not loading in designer as trim line is not present in surface specs
                            },
                        },
                    },
                },
            },
            zoomStrategy,
            widgets: {
                contextualToolbar: {
                    anchor: true,
                    containerElement: '.toolbar',
                },
                itemPlacementToolbar: {
                    enabled: true,
                    containerElement: '.itemtoolbar',
                    components: [
                        {
                            enabled: true,
                            name: 'ImagePercentage',
                        },
                    ],
                },
                createPrintPdf: {
                    containerElement: '.dcl-widget-print-pdf',
                    enabled: true,
                },
                tools: {
                    orientation: {
                        enabled: true,
                        containerElement: '.dcl-canvas-actions',
                    },
                    canvasAddRestrict: {
                        containerElement: '.dcl-canvas-actions',
                        enabled: false,
                    },
                    canvasBackground: {
                        containerElement: '.dcl-canvas-actions',
                        enabled: false,
                    },
                    canvasColors: {
                        containerElement: '.dcl-canvas-actions',
                        enabled: false,
                    },
                    canvasLock: {
                        containerElement: '.dcl-canvas-actions',
                        enabled: false,
                    },
                },
            },
            colors: {
                style: 'rounded',
                colorPickerPanels: {
                    customColor: {
                        rgb: {
                            enabled: true,
                        },
                        cmyk: {
                            enabled: true,
                        },
                    },
                },
            },
        },
        features: {
            // Configuration of the features section of the Designer.
            recolorization: {
                enabled: true,
            },
            premiumFinish: {
                enabled: true,
                useModal: false,
            },
            useSurfaceColorTypeForColorization: {
                enabled: true,
            },
            alignment: {
                enabled: true,
            },
        },
    };

    if (fontRepository) {
        const servicesConfig = {
            services: {
                clients: {
                    font: {
                        fontRepositoryUrl: `https://fonts.documents.cimpress.io/v1/repositories/${fontRepository}`,
                        print: {
                            fonts: fontListString ? fontListString.split(',') : [],
                        },
                    },
                },
            },
        };
        return { ...configuration, ...servicesConfig };
    }

    return configuration;
}

export const getDesignerConfiguration = (
    settings: any,
    product: any,
    documentReferenceUrl: string | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cimDoc: any,
    surfaceSpecifications: any,
    designerMode: string,
    tabsHidden: boolean,
    switcherHidden: boolean,
    locale?: string
) => {
    const { uploadTenant, apiKey, fontRepository, fonts } = settings;
    const extraMargins = {
        left: tabsHidden ? 0 : 340,
        right: switcherHidden ? 0 : 270,
        top: 55,
        bottom: 66,
    };
    const zoomStrategy = {
        resizeEnabled: true,
        initialWidth: 0.75,
        initialHeight: 0.75,
        initialZoom: 1,
        maxZoom: 3,
        minZoom: 0.75,
        zoomIncrement: 0.25,
    };
    const copiedSurfaces =
        surfaceSpecifications?.surfaces &&
        surfaceSpecifications.surfaces.map((surface: any) => ({
            ...surface,
        }));
    const config = {
        editorMode: designerMode,
        ...(product && product.sku && { mcpSku: product && product.sku }),
        surfaceVariables: product && product.attributes,
        documentReferenceUrl,
        cimDoc,
        ...(surfaceSpecifications &&
            surfaceSpecifications.surfaces && {
                surfaceSpecifications: {
                    surfaces: copiedSurfaces,
                },
            }),
        configuration: formatConfiguration(uploadTenant, apiKey, fontRepository, fonts, extraMargins, zoomStrategy, locale),
    };

    return config;
};

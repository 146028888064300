/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { availableShapes, ShapeSvg } from '@cimpress-technology/smart-shapes';
import { appTheme } from '../../theme';
import { ListSlider } from '../../components/ListSlider';

const baseStyle = css`
    width: 100%;
    background: ${appTheme.colors.secondaryBackground};
`;

const sliderContainerStyle = css`
    width: 100%;
    overflow: hidden;
    padding: 10px;
    position: relative;
    ${appTheme.mediaQueries.S} {
        padding: 0;
    }
`;

const shapeStyle = css`
    flex: 0 0 auto;
    width: 130px;
    height: 130px;
    margin: 10px;
    box-sizing: border-box;
    border: solid 1px ${appTheme.colors.primaryBorderColor};
    border-radius: 1px;
    background-color: ${appTheme.colors.primaryBackground};

    ${appTheme.mediaQueries.S} {
        width: 60px;
        height: 60px;
        margin: 5px;
    }

    position: relative;
    > img {
        position: absolute;
        left: calc(50% - 32px);
        top: calc(50% - 32px);
        width: 65px;
        height: 65px;

        ${appTheme.mediaQueries.S} {
            top: calc(50% - 15px);
            left: calc(50% - 15px);
            width: 30px;
            height: 30px;
        }
    }
`;

export function ShapeGallery() {
    return (
        <div css={baseStyle}>
            <h2>Sample smart shapes</h2>
            <div css={sliderContainerStyle}>
                <ListSlider>
                    {availableShapes &&
                        availableShapes.map((shape) => (
                            <div css={shapeStyle} key={shape.id}>
                                <ShapeSvg
                                    shape={shape}
                                    strokeWidth={6}
                                    stroke='gray'
                                    width={200}
                                    height={200}
                                    fillColor='none'
                                    isDraggable={false}
                                />
                            </div>
                        ))}
                </ListSlider>
            </div>
        </div>
    );
}

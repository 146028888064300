import { scrollToTop } from 'utils';
import { SideNavEntries } from '../../components/sidePanel/SideNavEntries';
import { SideNavEntry } from '../../components/sidePanel/SideNavEntry';
import { SideNavSection } from '../../components/sidePanel/SideNavSection';
import { DesignerIntegrationIndex } from './DesignerIntegrationIndex';
import { Overview } from './Overview';

export function IntegrationIndex() {
    return (
        <SideNavEntries>
            <SideNavSection path='integration' label='Integration Overview' page={<Overview />} />
            <SideNavSection
                path='integration/designer'
                label='Designer'
                page={<DesignerIntegrationIndex />}
                onClick={() => scrollToTop('sidenav-container')}
            >
                <SideNavEntry label='Step 1: Define custom shape tab using smart shape widget' path='#step1-integration' />
                <SideNavEntry label='Step 2: Add action element and event handler' path='#step2-integration' />
                <SideNavEntry label='Step 3: Add drag and drop feature' path='#step3-integration' />
                <SideNavEntry label='Live Demo' path='demo' />
            </SideNavSection>
        </SideNavEntries>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { HamburgerMenu } from './HamburgerMenu';
import { Logo } from './Logo';
import { UserInfo } from './UserInfo';
import { NavigationMenu } from './NavigationMenu';
import { useBreakpointInfo } from '../useBreakpointInfo';
import { appTheme } from '../../theme';
import { useLocation } from 'react-router-dom';

const getHeaderStyle = (showUserInfo: boolean) => css`
    display: flex;
    justify-content: ${showUserInfo ? 'space-between' : 'left'};
    justify-items: center;

    background-color: white;
    box-shadow: rgba(105, 117, 130, 0.23) 0px 1px 1px 0px;
    border: 1px solid rgba(105, 117, 130, 0.23);
    position: relative;
    height: 60px;

    padding: 0px 12px;
    ${appTheme.mediaQueries.M} {
        padding: 0 12px 0 0;
    }
`;

export interface ResponsiveHeaderProps {
    logoUrl: string;
    title: string;
    version: string;
    navMenuItems: {
        path: string;
        label: string;
    }[];
    showUserInfo?: boolean;
    className?: string;
}

export function ResponsiveHeader({ logoUrl, title, version, navMenuItems, showUserInfo = true }: ResponsiveHeaderProps) {
    const { isSmallDevice, isMediumDevice } = useBreakpointInfo();
    const isMinimalHeader = isSmallDevice || isMediumDevice;
    const [isMenuItemsOpen, setIsMenuItemsOpen] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [menuItems, setMenuItems] = useState(navMenuItems);
    const location = useLocation();

    useEffect(() => {
        const pathStrings = location.pathname.split('/');
        if (pathStrings.length > 1) {
            const menuPath = '/' + pathStrings[1];
            switch (menuPath) {
                case '/demo':
                    setSelectedMenu('Demo');
                    break;

                case '/documentation':
                    setSelectedMenu('Documentation');
                    break;

                case '/integration':
                case '/designer':
                    setSelectedMenu('Integration');
                    break;

                case '/support':
                    setSelectedMenu('Support');
                    break;

                case '/admin':
                    setMenuItems([
                        ...navMenuItems,
                        {
                            path: '/admin',
                            label: 'Admin',
                        },
                    ]);
                    setSelectedMenu('Admin');
                    break;
                default:
                    setSelectedMenu('');
                    break;
            }
        }
    }, [location, navMenuItems]);

    return (
        <header css={getHeaderStyle(showUserInfo)}>
            {isMinimalHeader && <HamburgerMenu isExpanded={isMenuItemsOpen} onToggle={(value: boolean) => setIsMenuItemsOpen(value)} />}
            <Logo
                logoUrl={logoUrl}
                title={title}
                version={version}
                onClick={() => {
                    setSelectedMenu('');
                    setIsMenuItemsOpen(false);
                }}
            />
            <NavigationMenu
                menuItems={menuItems}
                selectedMenu={selectedMenu}
                mode={isMinimalHeader ? 'drawer' : 'inline'}
                isOpen={isMenuItemsOpen}
                onSelect={(menu) => {
                    setSelectedMenu(menu);
                    setIsMenuItemsOpen(false);
                }}
            />
            {showUserInfo && <UserInfo isNameVisible={!isMinimalHeader} />}
        </header>
    );
}

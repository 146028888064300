/* eslint-disable no-multi-str */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { pageContent, pageTitle, subSectionHeader } from '../CommonStyles';
import { CodeBlock } from '../../components/codeBlock/CodeBlock';

export function DesignerIntegrationIndex() {
    const shapeTabComponent = `function ShapeTab({ designer }) {
        return (
            <SmartShapes ... />
        )
    }`;

    const shapeTabComponentWithDNDProp = `import { SmartShapes } from '@cimpress-technology/smart-shapes';

function ShapeTab({ designer }) {
    return (
        <SmartShapes
            dragAndDrop={
              {
                dropTargetClassName: 'drop-area-class-name',
                canvasToShapePercentage: 0.5,
                onDrop: (position) => { console.log(position.x, position.y); },
              }
            }
        ... />
    )
}`;

    const onDrop = `import { getCurrentSvg, Coordinate } from '@cimpress-technology/smart-shapes';

const onDrop = ({ x, y }: Coordinate) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const activeCanvasViewModel: any = designer && designer.documentRepository.getActiveCanvasViewModel();
    const zoomFactor = activeCanvasViewModel.attributes.zoomFactor;
    const droppableAreaPosition = document.getElementsByClassName(dropTargetClassName)[0].getBoundingClientRect();
    const activeCanvas: any = designer && designer.documentRepository.getActiveCanvas();

    const shapeDimensions = getShapeDimensions(activeCanvas);
    const strokeWidth = getStrokeWidthByCanvasSize(activeCanvas);

    getCurrentSvg().then((shapeSvg) => {
        // Get the svg string to create the loading shape
        // Host app should add their own Spinner implementation.
        // Add your own spinner here, width and height can be used to cerate a box to show the loader
        // for the box position also consider the drop area position, because the position(x , y) returned is relative to the drop area
        designer &&
            addShapeToDesigner(
                designer,
                {
                    left: x / zoomFactor,
                    top: y / zoomFactor,
                    width: shapeDimensions.width,
                    height: shapeDimensions.height,
                },
                strokeWidth
            ).then(() => {
                // Remove Spinner here
            });
    });
};`;

    const actionButtonLatest = '<button onClick={() => addShapeToDesigner(designer, positionAndSize)}>Add To Design</button>';
    const actionButtonOlder = '<button onClick={() => addShapeToDesigner(designer, positionAndSize, strokeWidth)}>Add To Design</button>';

    const eventHandlerFunctionOlder = `import { getCurrentShapeInRenderingFormat, Coordinate } from '@cimpress-technology/smart-shapes';

const createLockOverrides = (item: {
  lockOverrides: {
      fillColor: boolean;
      strokeColor: boolean;
      strokeWidth: boolean;
      sizeAndPosition: boolean;
  };
}) => ({
  fillColor: item.lockOverrides ? item.lockOverrides.fillColor : undefined,
  strokeColor: item.lockOverrides ? item.lockOverrides.strokeColor : undefined,
  strokeWidth: item.lockOverrides ? item.lockOverrides.strokeWidth : undefined,
  sizeAndPosition: item.lockOverrides ? item.lockOverrides.sizeAndPosition : undefined,
});

const modules = {
  curve: 'Curve',
  line: 'Line',
  rectangle: 'Rectangle',
  ellipse: 'Ellipse',
};

const addShapeToDesigner = (
    designer: Designer,
    positionAndSize: { top: number; left: number; width: number; height: number },
    strokeWidth: string | number
) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const activeCanvas: any = designer.documentRepository.getActiveCanvas();
    const { top, left, width, height } = positionAndSize;

    return getCurrentShapeInRenderingFormat(width, height).then((scaledShapes: any) => {
      const items = scaledShapes.map((scaledShape: any) => ({
            module: modules[scaledShape.type],
            curves: scaledShape.curves,
            viewBox: scaledShape.viewBox,
            width: scaledShape.position.width,
            height: scaledShape.position.height,
            strokeColor: scaledShape.stroke.color,
            fillColor: scaledShape.color,
            top,
            left,
            transforms: {
                rotatable: true,
                scalable: true,
            },
            isAspectRatioLocked: true,
            strokeWidth,
            lockOverrides: createLockOverrides(scaledShape),
            restricted: false,
            rotation: 0,
        }));
        const options = {
            model: activeCanvas,
            items,
            selectNewModels: true,
        };
        designer.commandDispatcher.add(options);
        return options;
    });
};`;

    const eventHandlerFunctionLatest = `import { getCurrentShapeInRenderingFormat, Coordinate } from '@cimpress-technology/smart-shapes';

const modules = {
  curve: 'Curve',
  line: 'Line',
  rectangle: 'Rectangle',
  ellipse: 'Ellipse',
};

const addShapeToDesigner = (
  designer: Designer,
  positionAndSize: { top: number; left: number; width: number; height: number },
) => {
  const { top, left, width, height } = positionAndSize;

  return getCurrentShapeInRenderingFormat(width, height).then((scaledShapes: any) => {
    const shapes = scaledShapes.map((scaledShape: any) => ({
          module: modules[scaledShape.type],
          curves: scaledShape.curves,
          viewBox: scaledShape.viewBox,
          width: scaledShape.position.width,
          height: scaledShape.position.height,
          strokeColor: scaledShape.stroke.color,
          strokeWidth: scaledShape.stroke.thickness,
          fillColor: scaledShape.color,
          top,
          left,
      }));
      designer.commandDispatcher.createShape({ shapes });
      return shapes;
  });
};`;

    const targetDimensionCalcFun = `const CanvasToShapePercentage = 0.50;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getShapeDimensions = (activeCanvas: any) => {
    const mmDimensions = {
    width: 0,
    height: 0,
    };
    const maxWidth = activeCanvas.attributes.width;
    const maxHeight = activeCanvas.attributes.height;
    mmDimensions.width = mmDimensions.height = Math.min(maxWidth, maxHeight) * CanvasToShapePercentage;
    return mmDimensions;
};`;

    const strokeWidthFun = `const MM_TO_PT = 2.83465;

const mm2pt = (mm: number) => mm * MM_TO_PT;

const getStrokeWidthByCanvasSize = (activeCanvas) => {
    const { attributes } = activeCanvas;
    let minSize = Math.min(attributes.width, attributes.height);
    const maxSize = Math.max(attributes.width, attributes.height);
    // If the smaller dimension is much smaller than the larger, then the strokeWidth will still be too small
    minSize = Math.max(minSize, maxSize * 0.33);
    const minSizeInCorrectMeasurement = mm2pt(minSize);
    // 0.5% of the canvas's smallest dimension yields a good line thickness
    return round(minSizeInCorrectMeasurement * 0.005, 3);
};`;

    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Integration with Cimpress Designer</h1>
            <h4 css={subSectionHeader}>How do I start using them?</h4>
            <p>
                This guide assumes that you already have <b>Cimpress Designer</b> configured and running on design experience web portal.
            </p>
            <p>
                For any help with designer, you can refer documentation below;
                <br />
                <a
                    href='https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/187105470/Cimpress+Designer+-+Quick+Start+Guide'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Cimpress Designer - Quick Start Guide
                </a>
            </p>
            <h4 css={subSectionHeader}>Prerequisite</h4>
            <div>
                <ul>
                    <li>Cimpress designer</li>
                </ul>
            </div>
            <div>
                <h4 css={subSectionHeader}>Before you start</h4>
                <div>
                    It is required that you already have custom UI widget setup with your designer studio. Custom UI tab interface
                    configured for adding content i.e, Image, text, shapes, etc.
                    <ul>
                        <li>Define custom UI for add content section</li>
                        <li>Pass 'designer' object reference to your UI component</li>
                        <li>Ensure class name defined for designer UI elements and sections</li>
                    </ul>
                </div>
                <h4 css={subSectionHeader} id='step1-integration'>
                    Step 1: Define custom shape tab using smart shape widget
                </h4>
                <p>Define one prop with designer object reference to link smart shape widget integration with designer API</p>
                <CodeBlock>{shapeTabComponent}</CodeBlock>
                <h4 css={subSectionHeader} id='step2-integration'>
                    Step 2: Add action element and event handler
                </h4>
                <p>Cimpress Designer version 3.2.0 and above, use createShape command API to add shape to Designer</p>
                <p>Add action button to allow adding selected shape to design area</p>
                <CodeBlock>{actionButtonLatest}</CodeBlock>
                <p>For event function, pass designer reference to access required design attributes and command API</p>
                <CodeBlock>{eventHandlerFunctionLatest}</CodeBlock>
                <p>Cimpress Designer version lower than 3.2.0, use add command to add shape to Designer</p>
                <p>Add action button to allow adding selected shape to design area</p>
                <CodeBlock>{actionButtonOlder}</CodeBlock>
                <p>For event function, pass designer reference to access required design attributes and command API</p>
                <CodeBlock>{eventHandlerFunctionOlder}</CodeBlock>
                <p>
                    You can create required helper functions to dynamically calculate parameters using active canvas attributes. e.g. target
                    dimensions, stroke width with respect to canvas dimensions
                </p>
                <CodeBlock>{targetDimensionCalcFun}</CodeBlock>
                <CodeBlock>{strokeWidthFun}</CodeBlock>
                <h4 css={subSectionHeader} id='step3-integration'>
                    Step 3: Add drag and drop feature
                </h4>
                <p>Pass one prop to enable drag and drop during shape widget integration with designer API</p>
                <CodeBlock>{shapeTabComponentWithDNDProp}</CodeBlock>
                <p>
                    onDrop will be called when we drop the shape onto the canvas. It will give us position of the dropped shape. With help
                    of this position and Active canvas, zoom factor of design area we can properly place the shape. Below is the code
                    example how we can write onDrop for designer.
                </p>
                <CodeBlock>{onDrop}</CodeBlock>
            </div>
        </div>
    );
}

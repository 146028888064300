/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { pageContent, pageTitle, subSectionHeader } from '../CommonStyles';

export function SupportIndex() {
    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Support</h1>
            <h4 css={subSectionHeader}>How do I submit a support request?</h4>
            <p>For any issues in using shape widget or integration with a design studio, please reach out to us using the below methods:</p>
            <ul>
                {/* <li>
                    If you have an urgent request, such as something that is severely negatively impacting your site and/or causing lost
                    revenue, please reach out via slack at{' '}
                    <a href='https://cimpress.slack.com/archives/CGQMQAB8V'>#templatemaker-support</a>.
                </li> */}
                <li>
                    For all requests, including questions about integrating, bug reports, or feature requests, please report through email
                    at &nbsp;
                    <a href='mailto:DesignAsset@cimpress.com'>DesignAsset@cimpress.com</a> and we will get back to you as soon as possible.
                </li>
                <li>
                    Are you on slack? Reach out to us at &nbsp;
                    <a href='https://cimpress.slack.com/archives/C021CLMLPC2' target='_blank' rel='noopener noreferrer'>
                        #ct-smartshapes-support
                    </a>
                </li>
            </ul>
            <h4 css={subSectionHeader}>What information should I include in my request?</h4>
            <p>
                Help us help you. Listed below is some information that could be helpful to the team when answering/debugging your support
                request.
            </p>
            <ul>
                <li>A summary of the problem/request</li>
                <li>
                    Recreation steps, including:
                    <ul>
                        <li>Props being passed to component</li>
                        <li>Any relevant images/links needed to recreate or visualize the problem</li>
                    </ul>
                </li>
                <li>A summary of the expected behavior</li>
                <li>Urgency of the problem/request</li>
            </ul>
        </div>
    );
}

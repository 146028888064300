/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { pageContent, pageTitle, subSectionHeader } from '../CommonStyles';
import { SvgPathUsageInstruction } from './SvgPathUsageInstruction';

export function SvgPath() {
    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Svg Path package overview</h1>
            <h3 css={subSectionHeader}>What does this package do?</h3>
            <p>
                In this package, we have exposed three functions GetPathData, GetPath and GetSvg that take shape and properties as arguments
                and returns raw path data (d), data with path tag and data with full svg tag respectively.
            </p>
            <h4 css={subSectionHeader} id='GettingStartedSvgPath'>
                Getting started
            </h4>
            <ul>
                <li>
                    Ensure your NPM user has access to CT Artifactory, if not goto{' '}
                    <Link to='/documentation/integration-setup'>package integration setup</Link>
                </li>
                <li>npm install @cimpress-technology/svg-path</li>
                <li>Use the functions found below</li>
            </ul>
            <SvgPathUsageInstruction />
        </div>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { ReactElement } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { jsx, css } from '@emotion/react';

import { parentActiveItemStyle, parentItemStyle } from '../../screens/CommonStyles';
import { SideNavEntryProps } from './SideNavEntry';

export interface SideNavSectionProps {
    children?: ReactElement<SideNavEntryProps>[] | ReactElement<SideNavEntryProps>;
    onClick?: () => void;
    page: ReactElement;
    label: string;
    path: string;
}

const docSectionHeader = css`
    padding-left: 0;
    margin-top: 15px;
    list-style: none;
`;

const subMenuItemStyle = css`
    position: relative;
    text-align: left;
    margin-top: 0;
    list-style-position: inside;

    /* square box style for list item */
    /* & li::before {
        content: "";
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 2px;
        border: 2px solid red;
    } */
`;

export function SideNavSection({ children, label, path, onClick }: SideNavSectionProps) {
    const match = useRouteMatch({ path: `/${path as string}`, exact: true });
    const isActive = !!match;
    return (
        <ul css={docSectionHeader}>
            <li>
                <Link
                    css={[parentItemStyle, isActive && parentActiveItemStyle]}
                    to={`/${path as string}`}
                    onClick={() => (onClick ? onClick() : null)}
                >
                    {label}
                </Link>
                <ul css={subMenuItemStyle}>{children}</ul>
            </li>
        </ul>
    );
}

import { AuthContext } from '../auth/AuthContext';
import { useContext } from 'react';

import { AdminContext } from './context/AdminContext';
import { getAssetList } from './services/asset.service';
import { getRenderingFormatViewUrl } from './utility/image.utility';
import { devIconFetchAppId, iconFetchAppId, IsDevDeployment } from './constants';

const appId = IsDevDeployment ? devIconFetchAppId : iconFetchAppId;
const assetLimit = 50;

export default function useFetchAssets() {
    const { setApprovedList, setRenderingView, setIsRenderingViewLoading, setNextOffset, setFetchingApprovedList } =
        useContext(AdminContext);
    const { accessToken } = useContext(AuthContext);

    const queryAssets = async (searchValue?: string, offset?: string) => {
        setFetchingApprovedList(true);
        const { assetList, offset: nextPageOffset } = await getAssetList(
            { authType: 'Bearer', value: accessToken },
            { limit: assetLimit, offset },
            { app: appId, ...(searchValue && { metadata: { [searchValue.toLowerCase()]: 'tags' } }) }
        );
        if (assetList && assetList.length) {
            setApprovedList(assetList);
            setFetchingApprovedList(false);
            setIsRenderingViewLoading(true);
            setNextOffset(nextPageOffset);
            const url = await getRenderingFormatViewUrl(assetList[0].imageUrl, { authType: 'Bearer', value: accessToken });
            if (url) {
                setRenderingView({
                    fileId: assetList[0].assetId,
                    fileUrl: url,
                });
                assetList[0].renderFormatUrl = url;
                setApprovedList(assetList);
            }
            setIsRenderingViewLoading(false);
        } else {
            setApprovedList([]);
            setFetchingApprovedList(false);
        }
    };

    return { queryAssets };
}

import { Button } from '@cimpress/react-components';

import './pagination.css';

interface PaginationProps {
    hasPrevPage: boolean;
    hasNextPage: boolean;
    onClickNext(payload: void): void;
    onClickPrev(payload: void): void;
}

export function Pagination({ hasNextPage, hasPrevPage, onClickNext, onClickPrev }: PaginationProps) {
    return (
        <div className='pagination__container'>
            <Button disabled={!hasPrevPage} onClick={onClickPrev}>
                {'<< Prev'}
            </Button>
            <Button disabled={!hasNextPage} onClick={onClickNext}>
                {'Next >>'}
            </Button>
        </div>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { pageContent, pageTitle, subSectionHeader } from '../CommonStyles';
import { SvgToCimdocInstruction } from './SvgToCimdocInstruction';

export function SvgToCimdoc() {
    return (
        <div css={pageContent}>
            <h1 css={pageTitle}>Svg To Cimdoc package overview</h1>
            <h3 css={subSectionHeader}>What does this package do?</h3>
            <p>
                In this package, we exposed a function convertSvgToCimdoc which is taking string svg, widthInMM, shouldMergePath (optional)
                and returns array of cimdoc rendering format objects.
            </p>
            <h4 css={subSectionHeader} id='GettingStartedSvgToCimdoc'>
                Getting started
            </h4>
            <ul>
                <li>
                    Ensure your NPM user has access to CT Artifactory, if not goto{' '}
                    <Link to='/documentation/integration-setup'>package integration setup</Link>
                </li>
                <li>npm install @cimpress-technology/svg-to-cimdoc</li>
                <li>Use the functions described below</li>
            </ul>
            <SvgToCimdocInstruction />
        </div>
    );
}

import RenderingFormatView from '../RenderingFormatView';
import UploadList from './UploadList';
import './allUploads.css';

const AllUploads = ({ searchValue }: { searchValue: string }) => {
    return (
        <div className='all-uploads-root'>
            <div className='all-uploads-div'>
                <UploadList searchValue={searchValue} />
            </div>
            <div className='all-uploads-rendering-view'>
                <RenderingFormatView />
            </div>
        </div>
    );
};

export default AllUploads;

import axios from 'axios';

import {
    URLs,
    COAM_GROUP_ID,
    SETTINGS_NAME,
    SETTINGS_RESOURCE_TYPE,
    SETTINGS_RESOURCE_ID,
    SETTINGS_COLLECTION_ASSET_ID,
} from '../constants';
import { AuthConfig, MetaData, ApproveItem, Collection, AssetQueryParams, AssetQueryBody } from '../admin.types';
import { toTitleCase } from './common.service';

export async function createAsset(file: Blob, authConfig: AuthConfig, obj: MetaData): Promise<{ error: string; id?: string }> {
    let id = '';
    try {
        const metaData = {};
        const collection = 'collection';
        metaData[collection] = obj.collection.value;
        obj.tagList.forEach((item) => {
            metaData[item] = 'tags';
        });

        const formData = new FormData();
        formData.append('file', file);
        formData.append('metadata', JSON.stringify(metaData));
        formData.append('managed', 'true');
        formData.append('expires', 'never');

        const { data } = await axios.post(URLs.SHERBERT_BASE + '/assets', formData, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
            },
        });

        if (data && data.id) {
            id = data.id;
            await axios.post(
                URLs.SHERBERT_BASE + `/assets/${id}:share`,
                {
                    coamGroupId: COAM_GROUP_ID,
                },
                {
                    headers: {
                        Authorization: `${authConfig.authType} ${authConfig.value}`,
                    },
                }
            );
        }
        return { error: '', id };
    } catch (err) {
        if (id) {
            try {
                await axios.delete(URLs.SHERBERT_BASE + `/assets/${id}`, {
                    headers: {
                        Authorization: `${authConfig.authType} ${authConfig.value}`,
                        'If-Match': '*',
                    },
                });
            } catch (err) {
                return { error: 'Error in creating Asset' };
            }
        }
        return { error: 'Error in creating Asset' };
    }
}

export async function getCollectionList(authConfig: AuthConfig) {
    try {
        const { data } = await axios.get(`${URLs.SETTINGS_API}/${SETTINGS_COLLECTION_ASSET_ID}`, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
            },
        });
        if (
            data &&
            data.settings &&
            data.settings.settings &&
            data.settings.settings.collections &&
            Array.isArray(data.settings.settings.collections)
        ) {
            const collections = data.settings.settings.collections;
            if (collections.length) {
                return { error: '', collections: data.settings.settings.collections };
            }
        }

        return { error: 'No Collections Available' };
    } catch (err) {
        return { error: 'Error in fetching collections ,Please try again ' };
    }
}

export async function updateCollectionAsset(authConfig: AuthConfig, collectionList: Collection[]) {
    try {
        const postSettingsObject = {
            settings: {
                collections: collectionList,
            },
            name: SETTINGS_NAME,
            resource: {
                type: SETTINGS_RESOURCE_TYPE,
                id: SETTINGS_RESOURCE_ID,
            },
        };
        await axios.put(`${URLs.SETTINGS_API}/${SETTINGS_COLLECTION_ASSET_ID}`, postSettingsObject, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
            },
        });
        return '';
    } catch (err) {
        return 'Error in adding new collection. Please try again';
    }
}

export async function deleteAsset(authConfig: AuthConfig, assetId: string) {
    try {
        await axios.delete(URLs.SHERBERT_BASE + `/assets/${assetId}`, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
                'If-Match': '*',
            },
        });
        return true;
    } catch (err) {
        return false;
    }
}

export async function deleteSelectedAssets(authConfig: AuthConfig, assetIdList: string[]) {
    try {
        const promises = assetIdList.map(async (assetId) => {
            const isDeleted = await deleteAsset(authConfig, assetId);
            return { id: assetId, isDeleted };
        });

        const deletedAssetList = await Promise.all(promises);
        const assetObj = {};
        deletedAssetList.forEach((item) => {
            assetObj[item.id] = item.isDeleted;
        });
        return { error: '', assetObj };
    } catch (err) {
        return { error: 'Error in deleting selected records' };
    }
}

export async function updateAsset(authConfig: AuthConfig, obj: MetaData, assetId: string, imageUrl: string) {
    try {
        const metaData = {};
        const collection = 'collection';
        metaData[collection] = obj.collection.value;
        obj.tagList.forEach((item) => {
            metaData[item] = 'tags';
        });

        const updateobj = {
            metadata: metaData,
            uri: imageUrl,
            expires: 'never',
        };
        await axios.put(`${URLs.SHERBERT_BASE}/assets/${assetId}`, updateobj, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
                'If-Match': '*',
            },
        });

        return true;
    } catch (err) {
        return false;
    }
}

export async function getAssetList(authConfig: AuthConfig, queryParams: AssetQueryParams, assetBody: AssetQueryBody) {
    const queryString = Object.keys(queryParams)
        .filter((param) => !!queryParams[param])
        .map((param) => `${param}=${queryParams[param]}`)
        .join('&');
    try {
        const { data } = await axios.post(`${URLs.SHERBERT_QUERY_POST_URL}?${queryString}`, assetBody, {
            headers: {
                Authorization: `${authConfig.authType} ${authConfig.value}`,
            },
        });
        if (data && data._embedded && data._embedded.item && Array.isArray(data._embedded.item)) {
            const newApproveList: ApproveItem[] = data._embedded.item.map((item: any) => {
                const { id, storage, metadata, uri } = item;
                const tagsArray: string[] = [];
                Object.entries(metadata || {}).forEach((item) => {
                    if (item[1] === 'tags') {
                        tagsArray.push(item[0]);
                    }
                });

                const obj = {
                    assetId: id || '',
                    size: storage?.fileSizeBytes || 0,
                    tags: tagsArray,
                    collection: { value: metadata?.collection || '', label: toTitleCase(metadata?.collection || '') },
                    imageUrl: uri || '',
                    assetName: storage?.fileName || '',
                    renderFormatUrl: '',
                };

                return obj;
            });
            return {
                assetList: newApproveList,
                offset: data.offset,
            };
        }
        return {};
    } catch (err) {
        return {};
    }
}

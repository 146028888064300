/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { CodeBlock } from 'components/codeBlock/CodeBlock';
import { tableStyle } from 'screens/CommonStyles';

const renderElement =
    // eslint-disable-next-line no-multi-str
    `import {SmartShapes, ThemeProvider, defaultTheme,AuthConfig, Theme } from '@cimpress-technology/smart-shapes';


const customizedTheme: Theme = {
    ...defaultTheme,
    primaryBackgroundColor: '#000000',
};
const authConfig: AuthConfig = {
    'authType':'Bearer',
    'value':'eyJhbGciOiJSUz...'
}

<ThemeProvider theme={customizedTheme}>
    <SmartShapes
        tenantId={tenantId}
        hostApp='Template-Maker'
        authConfig={authConfig}
        locale={locale}
        layout={Layout.PreviewOnTop}
        dragAndDrop={dragAndDrop}
    />
</ThemeProvider>
`;

const props = {
    primaryColor: 'Color used for buttons, indicators, etc',
    primaryHoverColor: 'Color for hover state on primary color',
    primaryActiveColor: 'Color for active state on primary color',
    inactiveColor: 'Color used for elements that are interactable, but not currently active',
    inactiveBorderColor: 'Border color used for elements that are interactable, but not currently active',
    primaryTextColor: 'Color used as the primary text color',
    primaryBackgroundColor: 'Color used as the primary background color (for modals and large areas containing other components)',
    secondaryBackgroundColor: 'Color used to contrast next to the primary background color (headings, footers, menus, etc)',
    primaryBorderColor: 'Color used as borders around our components',
};

const renderRows = () => {
    return Object.entries(props).map((prop) => {
        const length = prop.length;
        return (
            <tr key={prop[length - 2]}>
                <td>{prop[length - 2]}</td>
                <td>{prop[length - 1]}</td>
            </tr>
        );
    });
};

export function ThemeSetup() {
    return (
        <div>
            <p>
                You can provide custom theme to Smart Shape vaia `ThemeProvider`, a context-provider provided by `smart-shapes`. Otherwise
                it will use default theme. After installation of smart shapes widget, you will be able to import the `ThemeProvider,
                defaultTheme, Theme` into your application, and provide your theme from your React application.
            </p>
            <CodeBlock>{renderElement}</CodeBlock>
            <p>All values of Theme are listed below</p>

            <table css={tableStyle}>
                <thead>
                    <tr>
                        <th>Prop</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
            </table>
        </div>
    );
}

import { ChangeEvent } from 'react';
import { Button, TextField } from '@cimpress/react-components';
import { handleEnterKeyPress } from 'admin/utility/keyEvent.utility';

import './searchIcons.css';

interface SearchIconsProps {
    searchValue: string;
    setSearchValue: (value: string) => void;
    onSearch: () => void;
    onClearSearch: () => void;
}

export function SearchIcons({ searchValue, setSearchValue, onSearch, onClearSearch }: SearchIconsProps) {
    const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.currentTarget.value);
        if (e.currentTarget.value === '') {
            onClearSearch();
        }
    };

    return (
        <div className='search-icons'>
            <TextField
                label='Search icons'
                className='search-icons'
                value={searchValue}
                onChange={onChangeSearchValue}
                onKeyPress={handleEnterKeyPress(onSearch)}
                rightAddon={
                    <>
                        {searchValue && (
                            <Button className='search-clear-button' onClick={onClearSearch}>
                                X
                            </Button>
                        )}
                    </>
                }
            />
        </div>
    );
}

import { useState } from 'react';

import { Designer } from './type.designer';
import Images from './Images';
import Text from './Text';

import './tabs.css';
import Shapes from './Shapes';

interface Props {
    designer: Designer | undefined;
    tenantId: string;
    locale: string;
}

enum TabsEnum {
    IMAGE = 'image',
    TEXT = 'text',
    SHAPE = 'shape',
}

function GenerateSvgIcon(tabType: string) {
    return (
        <svg className='tab-item__icon'>
            <use className='tab-item__use' xlinkHref={`#dcl-icon-${tabType}`} />
        </svg>
    );
}

function GenerateTabItem(onClick: () => void, tabType: string, title: string, active: boolean) {
    return (
        <button
            type='button'
            className={`tab-item tab-item-${tabType} ${active ? ' tab-item--active' : ''}`}
            onClick={onClick}
            data-dcl-prevent-canvas-items-deselection
        >
            <div className='tab-item__icon-wrapper'>{GenerateSvgIcon(tabType)}</div>
            <div className='tab-item__title'>{title}</div>
        </button>
    );
}

/**
 * This is the component wrapper for the a lot of widgets that add/edit things on the canvas.
 */
function Tabs({ designer, tenantId, locale }: Props) {
    const [openTab, setOpenTab] = useState(TabsEnum.IMAGE);

    const openImages = () => setOpenTab(TabsEnum.IMAGE);
    const openText = () => setOpenTab(TabsEnum.TEXT);
    const openShape = () => setOpenTab(TabsEnum.SHAPE);

    return (
        <>
            <div className='tabs'>
                <div className='tabs__items'>
                    {GenerateTabItem(openImages, TabsEnum.IMAGE, 'Images', openTab === TabsEnum.IMAGE)}
                    {GenerateTabItem(openText, TabsEnum.TEXT, 'Text', openTab === TabsEnum.TEXT)}
                    {GenerateTabItem(openShape, TabsEnum.SHAPE, 'Shapes', openTab === TabsEnum.SHAPE)}
                </div>
                <div className='tabs__content'>
                    <Images hidden={openTab !== TabsEnum.IMAGE} />
                    <Text hidden={openTab !== TabsEnum.TEXT} />
                    <Shapes hidden={openTab !== TabsEnum.SHAPE} designer={designer} tenantId={tenantId} locale={locale} />
                </div>
            </div>
        </>
    );
}

export default Tabs;

import { css } from '@emotion/react';

export const scrollStyle = css`
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: #22485c;
    }

    ::-webkit-scrollbar-thumb {
        background: #73b1d1;
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #5491b0;
    }

    ::-moz-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-moz-scrollbar-track {
        background: #73b1d1;
    }

    ::-moz-scrollbar-thumb {
        background: #73b1d1;
        border-radius: 8px;
    }

    ::-moz-scrollbar-thumb:hover {
        background: #5491b0;
    }

    ::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }
`;

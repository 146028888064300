import { useState, useContext } from 'react';
import {
    getCurrentShapeInRenderingFormat,
    SmartShapes,
    Layout,
    getCurrentSvg,
    ThemeProvider,
    defaultTheme,
} from '@cimpress-technology/smart-shapes';
import { Designer } from './type.designer';
import ShapeSpinner from './ShapeSpinner';
import { AuthContext } from '../auth/AuthContext';

const dropTargetClassName = 'dcl-targets';
const CanvasToShapePercentage = 0.5;

interface Props {
    hidden: boolean;
    designer: Designer | undefined;
    tenantId: string;
    locale: string | undefined;
}

const modules = {
    curve: 'Curve',
    line: 'Line',
    rectangle: 'Rectangle',
    ellipse: 'Ellipse',
};

const addShapeToDesigner = (designer: Designer, positionAndSize: { top: number; left: number; width: number; height: number }) => {
    const { top, left, width, height } = positionAndSize;

    return getCurrentShapeInRenderingFormat(width, height).then((scaledShapes: any) => {
        const shapes = scaledShapes.map((scaledShape: any) => ({
            module: modules[scaledShape.type],
            curves: scaledShape.curves,
            viewBox: scaledShape.viewBox,
            width: parseFloat(scaledShape.position.width),
            height: parseFloat(scaledShape.position.height),
            strokeColor: scaledShape.stroke.color,
            strokeWidth: parseFloat(scaledShape.stroke.thickness),
            fillColor: scaledShape.color,
            top,
            left,
        }));

        designer.commandDispatcher.createShape({ shapes });
        return shapes;
    });
};

export default function ShapeTab({ hidden, tenantId, locale, designer }: Props) {
    const [currentShapeSvg, setCurrentShapeSvg] = useState<string>();
    const [shapeBox, setShapeBox] = useState<{ left: number; top: number; width: number; height: number }>();

    const { accessToken } = useContext(AuthContext);

    const onDrop = ({ x, y, height, width }: { x: number; y: number; height: number; width: number }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const activeCanvasViewModel: any = designer && designer.documentRepository.getActiveCanvasViewModel();
        const zoomFactor = activeCanvasViewModel.attributes.zoomFactor;
        const droppableAreaPosition = document.getElementsByClassName(dropTargetClassName)[0].getBoundingClientRect();

        getCurrentSvg().then((shapeSvg: string) => {
            // Get the svg string to create the loading shape
            // Host app should add their own Spinner implementation.
            setCurrentShapeSvg(shapeSvg);
            setShapeBox({
                left: droppableAreaPosition.left + x,
                top: droppableAreaPosition.top + y,
                width,
                height,
            });
            designer &&
                addShapeToDesigner(designer, {
                    left: x / zoomFactor,
                    top: y / zoomFactor,
                    width: width / zoomFactor,
                    height: height / zoomFactor,
                }).then(() => {
                    setCurrentShapeSvg(undefined);
                    setShapeBox(undefined);
                });
        });
    };

    const dragAndDrop = {
        dropTargetClassName,
        canvasToShapePercentage: CanvasToShapePercentage,
        onDrop,
    };

    return (
        <>
            {designer && (
                <div className={`content-panel${hidden ? ' content-panel--hidden' : ' content-panel--shapes'}`}>
                    <div className='content-panel--shapes-container'>
                        <div className='content-panel__title'>Shape</div>
                        {currentShapeSvg && shapeBox && <ShapeSpinner shapeSvg={currentShapeSvg} shapeBox={shapeBox} />}
                        <ThemeProvider theme={defaultTheme}>
                            <SmartShapes
                                tenantId={tenantId}
                                hostApp='Template-Maker'
                                locale={locale}
                                layout={Layout.PreviewOnTop}
                                dragAndDrop={dragAndDrop}
                                authConfig={{ authType: 'Bearer', value: accessToken }}
                            />
                        </ThemeProvider>
                    </div>
                </div>
            )}
        </>
    );
}

import { useContext } from 'react';
import { Card, shapes } from '@cimpress/react-components';
import cx from 'classnames';

import { AdminContext } from '../context/AdminContext';

const RenderingFormatView = () => {
    const { renderingView, isRenderingViewLoading } = useContext(AdminContext);
    const { Spinner } = shapes;
    return (
        <Card header='RENDERING FORMAT VIEW'>
            <div className={cx('render-format-div', isRenderingViewLoading && 'render-format-spinner-div')}>
                {!isRenderingViewLoading ? (
                    renderingView.fileUrl ? (
                        <img className='render-view-image' src={renderingView.fileUrl} alt='renderImage' />
                    ) : (
                        <h1>No Image to Preview</h1>
                    )
                ) : (
                    <Spinner />
                )}
            </div>
        </Card>
    );
};

export default RenderingFormatView;

export interface AppTheme {
    mediaQueries: { S: string; M: string; L: string; XL: string };
    colors: {
        primary: string;
        primaryText: string;
        primaryBackground: string;
        primaryBorderColor: string;
        primaryInactive: string;
        secondary: string;
        secondaryBackground: string;
        sectionBackground: string;
        sectionHighlightBackground: string;
        highlight: string;
        link: string;
        headerText: string;
        cardBorder: string;
        cardBackground: string;
        codeBlock: string;
        blockerBackground: string;
    };
}

export enum Breakpoint {
    S = 600,
    M = 768,
    L = 992,
    XL = 1200,
}

export const appTheme: AppTheme = {
    mediaQueries: {
        S: `@media (max-width: ${Breakpoint.S}px)`, // Small devices (portrait tablets and large phones, 600px and up)
        M: `@media (max-width: ${Breakpoint.M}px)`, // Medium devices (landscape tablets, 768px and up)
        L: `@media (max-width: ${Breakpoint.L}px)`, // Large devices (laptops/desktops, 992px and up)
        XL: `@media (max-width: ${Breakpoint.XL}px)`, // Extra large devices (large laptops and desktops, 1200px and up)
    },
    colors: {
        primary: '#0088a9',
        primaryText: '#3A414C',
        primaryBackground: '#FFF',
        primaryBorderColor: '#C4CDD6',
        primaryInactive: '#DFE0E2',
        secondary: '#0088a9',
        secondaryBackground: '#EFF3F6',
        sectionBackground: '#FFF',
        sectionHighlightBackground: '#ECF8F7',
        highlight: '#0088a9',
        link: '#0088a9',
        headerText: '#3A414C',
        cardBorder: '#C4CDD6',
        cardBackground: '#fff',
        codeBlock: '#F5F6F7',
        blockerBackground: '#fbdc14',
    },
};

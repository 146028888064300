/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useCurrentUser } from '../../auth';
import { appTheme } from '../../theme';

const userInfoStyle = css`
    display: flex;
    user-select: none;
`;

const nameContainerStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-right: 5px;
`;

const orgStyle = css`
    font-family: 'Gotham-Rounded-Book';
    font-size: 10px;
`;

const avatarStyle = css`
    display: flex;
    align-items: center;

    > img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
`;

export function UserInfo({ isNameVisible = true }: { isNameVisible?: boolean }) {
    const { user } = useCurrentUser();

    if (!user) {
        return (
            <div>
                <button>Login</button>
            </div>
        );
    }

    return (
        <div css={userInfoStyle}>
            {isNameVisible && (
                <div css={nameContainerStyle}>
                    <div>{user.name}</div>
                    <div
                        css={css`
                            ${orgStyle} color: ${appTheme.colors.primary};
                        `}
                    >
                        {user.tenant}
                    </div>
                </div>
            )}
            <div css={avatarStyle}>
                <img alt='avatar' src={user.avatar} />
            </div>
        </div>
    );
}

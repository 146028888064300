export function NpmUserSetup() {
    return (
        <div>
            <p>
                You will want to make sure your user can access our private npm repository and specifically namespace{' '}
                <code>@cimpress-technology</code>. If you have done this before for the namespace, you do not need to perform it everytime.
                Your system user's .npmrc should maintain the necessary credentials.
            </p>
            <ol>
                <li>
                    Go to your user profile page (click on your username in the top right) on Cimpress Artifactory:
                    https://artifactory.cimpress.io/
                </li>
                <li>Generate an API key if you do not have one. Copy this key as it will be used as your password in a following step.</li>
                <li>
                    Open a terminal or a command prompt and run:
                    <pre className='code-section'>
                        npm add-user --registry=https://artifactory.cimpress.io/artifactory/api/npm/npm-release-local/
                        --scope=@cimpress-technology
                    </pre>
                    <p>It will prompt you for your username, password, and email which are defined as follows:</p>
                    <p>
                        Username: Your email address without the @cimpress.com. (Should be all lowercase, ex: if your email is
                        john.doe@cimpress.com your username will be john.doe)
                    </p>
                    <p>Password: The saved API key that was generated from the user profile page on artifactory</p>
                    <p>
                        Email: Your Cimpress email address you used to login on Cimpress Artifactory. (should match the casing of your email
                        address, usually its all lowercase)
                    </p>
                </li>
                <li>After this completes you should be able to install any package from the namespace / scope you specified in step 2</li>
            </ol>
        </div>
    );
}

import React, { useState, createContext, useEffect } from 'react';
import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper';

import { ProviderProps, AuthProfile } from '../admin.types';
import { initialAuthContext } from '../constants';

const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

export const AuthContext = createContext(initialAuthContext);

function parseTenant(value: string) {
    if (value && value === 'cimpress') {
        return 'Cimpress Technology';
    }

    return value;
}

function parseUserProfile(profile: AuthProfile | undefined) {
    if (profile) {
        const { name, picture: avatar } = profile;
        // Due to deprecation of `['https://claims.cimpress.io/tenants'] it is no longer guaranteed to be there.
        const tenant = parseTenant((profile['https://claims.cimpress.io/tenants'] ?? [])[0]);
        return {
            name,
            avatar,
            tenant,
        };
    }

    return undefined;
}

export function AuthProvider({ children }: ProviderProps) {
    const [authContext, setAuthContext] = useState(initialAuthContext);
    useEffect(() => {
        setAuthContext((state) => ({ ...state, isAuthenticating: true }));

        const auth = new CentralizedAuth({
            clientID,
            redirectRoute: '/',
        });

        function login() {
            auth.login({ nextUri: window.location.pathname, forceLogin: true });
        }

        (async () => {
            try {
                await auth.ensureAuthentication({
                    nextUri: window.location.pathname + window.location.search,
                });
                auth.on('tokenExpired', login);
                const accessToken = auth.getAccessToken();
                const profile = auth.getProfile();

                const authContextValue = {
                    accessToken,
                    user: parseUserProfile(profile),
                };

                setAuthContext((state) => ({ ...state, ...authContextValue }));
            } catch (err) {
                setAuthContext((state) => ({ ...state, error: err.message }));
            }

            setAuthContext((state) => ({ ...state, isAuthenticating: false }));
        })();

        return () => {
            auth.removeListener('tokenExpired', login);
        };
    }, [setAuthContext]);

    return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
}

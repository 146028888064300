import ReactDOM from 'react-dom';
import { ReactComponent as Spinner } from './../images/spinner.svg';

interface Props {
    shapeSvg: string;
    shapeBox: { top: number; left: number; width: number; height: number };
}

export default function ShapeSpinner({ shapeSvg, shapeBox }: Props) {
    const { top, left, width } = shapeBox;
    return ReactDOM.createPortal(
        <div
            style={{
                position: 'absolute',
                opacity: '0.5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left,
                top,
                width,
            }}
        >
            <img src={`data:image/svg+xml;utf8,${shapeSvg.replace(/#/g, '%23')}`} alt='' />
            <div style={{ position: 'absolute' }}>
                <Spinner />
            </div>
        </div>,
        document.body
    );
}

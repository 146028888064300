/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { appTheme } from '../../theme';

const menuIconStyle = css`
    cursor: pointer;
    padding: 20px;
    border: none;
    background: inherit;
`;

const navIconStyle = css`
    background: ${appTheme.colors.primary};
    display: block;
    height: 2px;
    position: relative;
    width: 1.625rem;

    &:before,
    &:after {
        background: ${appTheme.colors.primary};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
    }

    &:before {
        top: 7px;
    }
    &:after {
        top: -7px;
    }
`;

const navCloseIconStyle = css`
    background: transparent;

    &:before,
    &:after {
        top: 0;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }
`;

export interface HamburgerMenuProps {
    isExpanded: boolean;
    onToggle: (isExpanded: boolean) => void;
    className?: string;
}

export function HamburgerMenu({ isExpanded, onToggle, className }: HamburgerMenuProps) {
    return (
        <button
            className={className}
            type='button'
            css={menuIconStyle}
            onClick={() => {
                onToggle(!isExpanded);
            }}
        >
            <span css={[navIconStyle, isExpanded ? navCloseIconStyle : '']} />
        </button>
    );
}

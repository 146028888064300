/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { appTheme } from '../theme';
import { ReactComponent as ArrowLeft } from './svgs/arrowLeft.svg';
import { ReactComponent as ArrowRight } from './svgs/arrowRight.svg';

const getContainerStyle = () => css`
    /* if you want to have a scrollbar, you can add overflow here */
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    scroll-behavior: smooth;
    ${appTheme.mediaQueries.S} {
        margin: 0 40px;
    }

    .slider-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .btn {
        background: none;
        border: none;
        position: absolute;
        padding: 5px;
        cursor: pointer;
        color: ${appTheme.colors.primary};
        line-height: 0;

        svg {
            height: 25px;
            width: 25px;
        }
    }

    .prev {
        left: 0;
    }

    .next {
        right: 0;
    }

    .disable {
        //opacity: 0.5;
        pointer-events: none;
        color: ${appTheme.colors.primaryInactive};
    }

    .child {
        padding: 5px 20px;
        background: #59eb97;
        text-align: center;
        width: 100%;
        margin-right: 10px;
        border-radius: 21px;
    }
`;

export interface ListSliderProps {
    children: ReactNode | ReactNode[];
}

export function ListSlider({ children }: ListSliderProps) {
    const [prevDisable, setPrevDisable] = useState(true);
    const [nextDisable, setNextDisable] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkButtons = (offsetWidthValue: number, scrollWidthValue: number) => {
        // due to smooth scroll behavior, scroll offset takes time to update
        // so adding timeout function to delay update
        setTimeout(() => {
            if (contentRef.current) {
                setPrevDisable(contentRef.current?.scrollLeft <= 0);
                setNextDisable(contentRef.current.scrollLeft + offsetWidthValue >= scrollWidthValue);
            }
        }, 1000);
    };

    useEffect(() => {
        if (contentRef.current) {
            checkButtons(contentRef.current?.offsetWidth, contentRef.current?.scrollWidth);
        }
    }, [checkButtons]);

    return (
        <div className='slider-container' ref={contentRef} css={getContainerStyle}>
            <div className='slider-wrapper'>{children}</div>
            <button
                className={`btn prev ${prevDisable ? 'disable' : ''}`}
                disabled={prevDisable}
                onClick={() => {
                    if (contentRef.current) {
                        contentRef.current.scrollLeft -= contentRef.current.offsetWidth / 2;
                        checkButtons(contentRef.current.offsetWidth, contentRef.current.scrollWidth);
                    }
                }}
            >
                <ArrowLeft
                    css={css`
                        width: 100%;
                    `}
                    viewBox='0 0 24 24'
                />
            </button>
            <button
                className={`btn next ${nextDisable ? 'disable' : ''}`}
                disabled={nextDisable}
                onClick={() => {
                    if (contentRef.current) {
                        contentRef.current.scrollLeft += contentRef.current.offsetWidth / 2;
                        checkButtons(contentRef.current.offsetWidth, contentRef.current.scrollWidth);
                    }
                }}
            >
                <ArrowRight
                    css={css`
                        width: 100%;
                    `}
                    viewBox='0 0 24 24'
                />
            </button>
        </div>
    );
}

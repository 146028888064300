import { useState, DragEvent } from 'react';

import './fileDropZone.css';
import { FileDropZoneProps } from '../../admin.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFileFromDataTransfer = (dataTransfer: any): File[] => {
    if (dataTransfer.items) {
        const dataTransferItems = dataTransfer.items;
        const files = Object.keys(dataTransferItems)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((item: any) => dataTransferItems[item].kind === 'file')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((item: any) => dataTransferItems[item].getAsFile());
        return files;
    }
    return dataTransfer.files;
};

export function FileDropZone({ children, onFileUpload }: FileDropZoneProps) {
    const [isDragOver, setIsDragOver] = useState(false);

    const dropHandler = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = getFileFromDataTransfer(event.dataTransfer);
        onFileUpload(files);
        setIsDragOver(false);
    };

    const dragoverHandler = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = 'copy';
    };
    const ondragenter = () => setIsDragOver(true);
    const ondragleave = () => setIsDragOver(false);

    return (
        <div className='file-dropzone-wrapper'>
            <div
                className={`file-dropzone ${isDragOver ? 'file-drag-over' : ''}`}
                onDrop={dropHandler}
                onDragOver={dragoverHandler}
                onDragEnter={ondragenter}
                onDragLeave={ondragleave}
            >
                {children}
            </div>
        </div>
    );
}

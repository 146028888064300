/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { appTheme } from '../../theme';
import { primaryButtonStyle, secondaryButtonStyle } from '../CommonStyles';
import { ShapeGallery } from './ShapeGallery';
import { ReactComponent as HeroSvg } from './svgs/ss-hero1.svg';
import { ReactComponent as SampleShapeSvg } from './svgs/sample-shape.svg';
import widgetScreenshot from './screenshots/widget.png';
import designerIntegrationScreenshot from './screenshots/designer-integration.png';

const pageStyle = css`
    font-family: 'Open Sans';
    color: ${appTheme.colors.primaryText};
    padding-top: 15px;

    h1,
    h2,
    h4 {
        font-family: 'Gotham-Rounded-Book';
        font-weight: 300;
    }

    ul {
        text-align: left;
        margin-top: 0;
        padding: 0;
        list-style: none;
        position: relative;
    }

    li {
        line-height: 25px;
    }

    /* square box style for list item */
    li::before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 3px;
        border: 3px solid ${appTheme.colors.primary};
    }

    a {
        color: ${appTheme.colors.primary};
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    button {
        font-family: inherit;
        font-size: inherit;
    }
`;

const headerLogo = css`
    width: 96%;
    height: 100%;
    margin-bottom: -48%;
    margin-left: 2%;
    fill: #c4cdd6;
`;

const darkButtonStyle = css`
    ${primaryButtonStyle}
    height: 48px;
    width: 240px;
    ${appTheme.mediaQueries.S} {
        width: 200;
    }
`;

const lightButtonStyle = css`
    ${secondaryButtonStyle}
    border: none;
    font-weight: 600;
    height: 48px;
    width: 210px;
    ${appTheme.mediaQueries.S} {
        width: 200;
    }
`;

const headerTitleSection = css`
    width: 35%;
    min-width: 245px;

    ${appTheme.mediaQueries.M} {
        width: 90%;
    }
`;

const sampleShapeSection = css`
    display: inline-block;
    margin: 0 5%;
    width: 40%;
    text-align: center;

    ${appTheme.mediaQueries.M} {
        width: 90%;
        margin-top: 15px;
    }

    svg {
        background: ${appTheme.colors.secondaryBackground};
    }
`;

const pageSubSection = css`
    display: inline-block;
    background-color: ${appTheme.colors.sectionBackground};
    border-radius: 4px;
    width: 90%;
    box-sizing: border-box;
    padding: 32px;
    margin: 0 5%;

    ${appTheme.mediaQueries.S} {
        padding: 15px;
    }

    h2 {
        display: flex;
        align-items: center;
        margin: 10px 0 15px 0;

        //logo style
        img {
            width: 45px;
            height: 45px;
            margin-right: 16px;
        }
    }

    img {
        width: 100%;
    }

    .left-section {
        width: 50%;
        float: left;
        padding-right: 25px;
        box-sizing: border-box;

        ${appTheme.mediaQueries.M} {
            padding: 0;
            width: 100%;
        }
    }

    .right-section {
        width: 50%;
        display: inline-block;
        padding-left: 25px;
        box-sizing: border-box;

        ${appTheme.mediaQueries.M} {
            padding: 0;
            width: 100%;
        }
    }

    p {
        font-size: 17px;
    }

    a {
        font-size: 14px;
    }
`;

const sectionFooterStyle = css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 0px 0;

    ${appTheme.mediaQueries.S} {
        justify-content: center;
    }

    a {
        font-weight: 600;
    }
`;

export function Home() {
    return (
        <div css={pageStyle}>
            <HeroSvg css={headerLogo} />
            <div
                css={css`
                    ${pageSubSection} ${headerTitleSection}
                `}
            >
                <h2>
                    <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt='' />
                    SmartShapes&trade;
                </h2>
                <div>
                    <p>
                        A unique solution to help businesses create custom shapes for their customers, and integrate shape editing and
                        gallery experiences into their workflow
                    </p>
                    <Link to='/documentation'>Learn More</Link>
                </div>
                <div css={sectionFooterStyle}>
                    <Link to='/demo'>
                        <button css={lightButtonStyle}>Try Our Product Demo</button>
                    </Link>
                    <Link to='/documentation'>
                        <button css={darkButtonStyle}>Get Started</button>
                    </Link>
                </div>
            </div>
            <div css={sampleShapeSection}>
                <SampleShapeSvg />
            </div>
            <div
                css={css`
                    ${pageSubSection} background: none;
                    padding-left: 0;
                    padding-right: 0;
                `}
            >
                <ShapeGallery />
            </div>
            <div
                css={css`
                    ${pageSubSection};
                `}
            >
                <div className='left-section'>
                    <img src={widgetScreenshot} alt='' />
                </div>
                <div className='right-section'>
                    <h2>Quickly Create Shapes For Your Customers</h2>
                    <div>
                        <ul>
                            <li>Start with dozens of default shapes we provide for you.</li>
                            <li>Modify these to create your own variations.</li>
                            <li>Create custom shapes from scratch using drawing tools.</li>
                            <li>Save and reuse your shapes from the shape gallery.</li>
                        </ul>
                        <br />
                        <Link to='/demo'>Learn More</Link>
                    </div>
                </div>
            </div>
            <div
                css={css`
                    ${pageSubSection} background: none;
                `}
            >
                <div className='left-section'>
                    <h2>Integrate SmartShapes Into Your Design Studio</h2>
                    <div>
                        <ul>
                            <li>Include the SmartShapes tool widget or embed it seamlessly in your studio.</li>
                            <li>Pick and choose what features you want customers to use.</li>
                            <li>Connect to your own shape content and edit it using SmartShapes.</li>
                            <li>Restyle and change to suit your banding and style guide.</li>
                        </ul>
                        <br />
                        <Link to='/integration'>Learn More</Link>
                    </div>
                </div>
                <div
                    className='right-section'
                    css={css`
                        ${appTheme.mediaQueries.M} {
                            margin-top: 15px;
                        }
                    `}
                >
                    <img src={designerIntegrationScreenshot} alt='' />
                </div>
            </div>
            {/* <div css={css`${pageSubSection} padding-left: 50%; min-height: 450px; margin-bottom: 4%;`}>
                <h2>
                    Reuse and Share SmartShape Galleries across MCP
                </h2>
            </div> */}
        </div>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { CodeBlock } from '../../components/codeBlock/CodeBlock';

const rootNode = '<div id="root"></div>';

export function UsageInstructions() {
    const renderElement =
        // eslint-disable-next-line no-multi-str
        'const element = (<SmartShapes\n \
      locale="en-US"\n \
      hostApp="SmartShapeDemo"\n \
      tenantId="SceneTestGroup"\n \
      authConfig={{"authType":"Bearer","value":"eyJhbGciOiJSUz..."}}\n \
      dragAndDrop={{\n \
          dropTargetClassName: "drop-area-class-name"\n \
          canvasToShapePercentage: 0.5\n \
          onDrop: ((position)=> { console.log(position.x, position.y); })\n \
      }}\n \
      />);\n  \
      \n \
ReactDOM.render(element, document.getElementById("root"));';

    return (
        <div>
            <p>
                Now that we have installed the smart shapes widget, you're able to import the `Smart-shapes` component into your
                application. In order to use the new component, you'll need to import it into your React application.
            </p>
            <CodeBlock>{'import { SmartShapes, Layout } from "@cimpress-technology/smart-shapes";'}</CodeBlock>
            <p>
                Once the <b>smart shapes</b> is imported, you are able to render it into the DOM ({rootNode}). See example below:
            </p>
            <CodeBlock>{renderElement}</CodeBlock>
        </div>
    );
}

import axios from 'axios';
import pako from 'pako';
import { convertSvgToCimdoc, RenderingFormat } from '@cimpress-technology/svg-to-cimdoc';

import { MAX_FILE_SIZE_LIMIT, URLs, FONT_REPOSITORY_URL, INSTRUCTIONS_URL_SIZE_LIMIT } from '../constants';
import { AuthConfig, CimDoc } from '../admin.types';

export function isValidImageFormat(imageString: string): boolean {
    return imageString.toLowerCase().includes('svg');
}

export function isValidImageSize(imageSize: number): boolean {
    return imageSize <= MAX_FILE_SIZE_LIMIT;
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

async function getCimDocFromSvg(file: Blob | string): Promise<any> {
    let svgString = '';
    const mmWidth = 30;
    const shouldMergePath = true;

    if (typeof file === 'string') {
        svgString = (await axios.get(file)).data;
    } else {
        svgString = await file.text();
    }
    const cimdocShapes: RenderingFormat[] = await convertSvgToCimdoc({
        svgString,
        widthInMM: mmWidth,
        shouldMergePath,
    });
    if (cimdocShapes && Array.isArray(cimdocShapes)) {
        return generateCimDoc(cimdocShapes);
    }
    return undefined;
}

export async function getRenderingFormatViewUrl(file: Blob | string, authConfig: AuthConfig): Promise<string> {
    try {
        const cimDocObj: CimDoc | undefined = await getCimDocFromSvg(file);
        if (cimDocObj) {
            const renderingObj = compress(cimDocObj);
            if (renderingObj.encodedUrl && renderingObj.encodedUrl.length > INSTRUCTIONS_URL_SIZE_LIMIT) {
                const res = await getLinkFromDocument(cimDocObj, authConfig);
                return res;
            }
            return renderingObj.renderingUrl;
        }
        return '';
    } catch (err) {
        return '';
    }
}

export async function getLinkFromDocument(cimDoc: CimDoc, authConfig: AuthConfig): Promise<string> {
    const body = {
        deleteAfterDays: 1,
        document: cimDoc.document,
        fontRepositoryUrl: FONT_REPOSITORY_URL,
        version: '2.0',
    };
    const { data } = await axios.post(URLs.UDS_DOCUMENT_API, body, {
        headers: {
            Authorization: `${authConfig.authType} ${authConfig.value}`,
        },
    });
    if (data && data._links && data._links.previewInstructions && data._links.previewInstructions && data._links.previewInstructions.href) {
        const encodedUrl = data._links.previewInstructions.href;
        const renderingUrl = `https://rendering.documents.cimpress.io/v1/cse/preview?width=1000&instructions_uri=${encodeURIComponent(
            encodedUrl
        )}`;
        return renderingUrl;
    }
    return '';
}

function compress(cimDoc: CimDoc): { renderingUrl: string; encodedUrl: string } {
    const content = JSON.stringify(cimDoc);

    const isCimdoc = content.includes('version') || content.includes('Version');

    const deflatedContent = pako.deflateRaw(content, { to: 'string' });
    const encodedContent = encodeURIComponent(btoa(deflatedContent));
    const instructionType = 'preview';
    const environment = '';
    let encodedUrl;
    if (isCimdoc) {
        encodedUrl = `https://${environment}uds.documents.cimpress.io/v3/instructions:${instructionType}?documentUri=${encodedContent}`;
    } else {
        encodedUrl = `https://${environment}scenes.documents.cimpress.io/v3/scenes:generate?data=${encodedContent}`;
    }
    encodedUrl = encodeURIComponent(encodedUrl);
    if (isCimdoc) {
        const renderingUrl = `https://rendering.documents.cimpress.io/v1/cse/preview?width=1000&instructions_uri=${encodedUrl}`;

        return { renderingUrl, encodedUrl };
    }
    return {
        renderingUrl: '',
        encodedUrl,
    };
}

function generateCimDoc(arr: RenderingFormat[]): CimDoc {
    const max = [0, 0]; // to know the max height,width on overall svg
    const shapesList = arr.map((item) => {
        max[0] = Math.max(parseFloat(item.position.height), max[0]);
        max[1] = Math.max(parseFloat(item.position.width), max[1]);
        return {
            ...item,
            rotationAngle: 0,
        };
    });
    const obj = {
        version: '2.0',
        document: {
            surfaces: [
                {
                    name: 'Item',
                    width: `${max[1] + 10}mm`,
                    height: `${max[0] + 10}mm`,
                    decorationTechnology: 'heatTransfer',
                    shapes: shapesList,
                },
            ],
        },
        fontRepositoryUrl: FONT_REPOSITORY_URL,
    };
    return obj;
}
